import { Injectable } from '@angular/core';
import { Establishment, Machine, Plate } from 'app/shared/models';
import { EstablishmentService } from './establishment.service';
import { MachineService } from './machine.service';
import { PlateService } from './plate.service';

@Injectable({
  providedIn: 'root',
})
export class MachinePlateByEstablishmentService {
  constructor(
    private machineService: MachineService,
    private establishmentService: EstablishmentService,
    private plateService: PlateService,
  ) {}

  async getMachines(machines: Machine[], establishments?: Establishment[]): Promise<Machine[]> {
    let internalEstablishments = establishments;
    if (!internalEstablishments) {
      internalEstablishments = await this.establishmentService.getEstablishments();
    }

    const establishmentsMap = new Map<string, Establishment>();
    internalEstablishments.forEach((establishment) => {
      establishmentsMap.set(establishment.id, establishment);
    });

    const plates = await this.plateService.getPlates();
    const platesMap = new Map<string, Plate>();
    plates.forEach((plate) => {
      platesMap.set(plate.deviceId, plate);
    });

    return machines.map((machine) => {
      machine.setEstablishment(establishmentsMap.get(machine.establishmentId));
      machine.setPlate(platesMap.get(machine.deviceId));
      return machine;
    });
  }

  async getMachine(machineId: string): Promise<Machine> {
    const machine = await this.machineService.getMachine(machineId);

    if (machine.establishmentId) {
      const establishment = await this.establishmentService.getEstablishment(machine.establishmentId);
      machine.setEstablishment(establishment);
    }

    if (machine.deviceId) {
      const plate = await this.plateService.getPlate(machine.deviceId);
      machine.setPlate(plate);
    }

    return machine;
  }

  async getMachinesByEstablishments(establishments: Establishment[]): Promise<Map<string, Machine[]>> {
    const machinesByEstablishmentMap = new Map<string, Machine[]>();

    const machinesByEstablishments = await Promise.all(
      establishments.map((est) => this.getMachinesByEstablishment(est)),
    );

    establishments.forEach(({ id }, index) => {
      machinesByEstablishmentMap.set(id, machinesByEstablishments[index]);
    });

    return machinesByEstablishmentMap;
  }

  async getMachinesByEstablishment(establishment: Establishment): Promise<Machine[]> {
    const machines = await this.machineService.getMachinesByEstablishment(establishment.id);
    const completeMachines = this.getMachines(machines, [establishment]);

    return completeMachines;
  }
}
