import { Serializable } from 'app/core/interfaces';
import { PlateDTO } from '../interfaces/dto/plateDTO';
import { OperatorCompany } from './operatorCompany';

export enum TypeStatePlate {
  Active = 'Active',
  Inactive = 'Inactive',
  // Disconnected = 'Disconnected',
  // OutOfService = 'Out of service',
}
export class Plate implements Serializable<Plate> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deviceId: string;
  operatorCompanyId?: string;
  operatorCompany?: OperatorCompany;
  state: TypeStatePlate;

  deserialize(input: PlateDTO): Plate {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
    this.deviceId = input.deviceId;
    this.state = TypeStatePlate.Active;

    this.operatorCompanyId = input.organizationId || null;

    return this;
  }

  setOperatorCompany(operatorCompany: OperatorCompany): void {
    if (operatorCompany?.id === this.operatorCompanyId) {
      this.operatorCompany = operatorCompany;
    }
  }

  deserializeArray(inputArray: Array<PlateDTO>): Plate[] {
    const plateArray: Array<Plate> = inputArray.map((input) => new Plate().deserialize(input));
    return plateArray;
  }
}

export const statePlateOptions = Object.entries(TypeStatePlate).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));
