<div class="group-search">
  <input
    class="form-control"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="input"
    (ngModelChange)="onChange($event)"
    (focus)="isInputSearchFocused = true"
  />
  <button type="button" class="px-3 py-2" (click)="onClickFn()">
    <fa-icon class="nav-icon d-flex" [icon]="faSearch"></fa-icon>
  </button>

  <div class="input-responsive" [class.focus]="isInputSearchFocused" *ngIf="isInputSearchFocused">
    <input
      class="form-control"
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="input"
      (ngModelChange)="onChange($event)"
    />
    <button type="button" class="px-3 py-2" (click)="onCloseFn()">
      <fa-icon class="nav-icon d-flex" [icon]="faTimes"></fa-icon>
    </button>
  </div>
</div>
