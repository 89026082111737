import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoggedInGuard, IsAdminLoggedGuard, NoIsAdminLoggedGuard } from 'app/modules/auth/guards';
import { AuthService } from 'app/modules/auth/services';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  APP_SIDEBAR_NAV,
  AppPaginationComponent,
  AppTooltipSelectedItemsComponent,
  AppSelectComponent,
  AppGroupSearchComponent,
  AppFormAddUserComponent,
  AppLinkUnlinkPlateComponent,
} from './components';
import { SidebarToggleDirective, HasPermissionDirective, NAV_DROPDOWN_DIRECTIVES } from './directives';
import { P404PageComponent } from './pages';

const PAGES = [P404PageComponent];

const COMPONENTS = [
  AppHeaderComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  AppPaginationComponent,
  AppTooltipSelectedItemsComponent,
  AppSelectComponent,
  AppGroupSearchComponent,
  AppFormAddUserComponent,
  AppLinkUnlinkPlateComponent,
  ...APP_SIDEBAR_NAV,
];

const PROVIDERS: Provider[] = [AuthService, LoggedInGuard, IsAdminLoggedGuard, NoIsAdminLoggedGuard];

const MODULES = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  NgxSpinnerModule,
  NgChartsModule,
  NgbModule,
  NgxPaginationModule,
  ToastrModule.forRoot(),
  NgSelectModule,
];

const DIRECTIVES: Provider[] = [SidebarToggleDirective, HasPermissionDirective, ...NAV_DROPDOWN_DIRECTIVES];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    ...(MODULES as []),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as [])],
  exports: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as []), ...(MODULES as []), TranslateModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS, ...DIRECTIVES],
    } as ModuleWithProviders<SharedModule>;
  }

  constructor(private translate: TranslateService) {
    this.initTranslateService();
  }

  private initTranslateService(): void {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');

    // user browser language
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/es|en/) ? browserLang : 'es');
  }
}
