import { TypeToCollect } from '../enums';
import { Machine } from './machines';
import { TelemetryDetail } from './telemetry';

interface TelemetryMachineProps {
  machine: Machine;
  telemetryDetail: TelemetryDetail;
  lastCollection: Date;
}
export class TelemetryMachine {
  machine: Machine;
  telemetryDetail: TelemetryDetail;
  lastCollection: Date;

  constructor(props: TelemetryMachineProps) {
    this.machine = props.machine;
    this.telemetryDetail = props.telemetryDetail;
    this.lastCollection = props.lastCollection;
  }

  get collect(): TypeToCollect {
    const { telemetryDetail, machine } = this;
    if (telemetryDetail.amount > machine.valueCollect) {
      return TypeToCollect.Yes;
    }
    if (telemetryDetail.amount < machine.valueNotCollect) {
      return TypeToCollect.No;
    }
    return TypeToCollect.Medium;
  }

  get daysToLastCollection(): number {
    if (!this.lastCollection) return 0;
    const difference = new Date().getTime() - this.lastCollection.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }

  get nextCollection(): number {
    const next = 7 - this.daysToLastCollection;
    return next > 0 ? next : 0;
  }
}
