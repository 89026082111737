import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faSignOutAlt, faBars, faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { faBell } from '@fortawesome/free-regular-svg-icons';

import { AuthService } from 'app/modules/auth/services';
import { User } from 'app/shared/models';

@Component({
  selector: 'app-header-menu',
  templateUrl: './app-header-menu.component.html',
  styleUrls: ['./app-header-menu.component.scss'],
})
export class AppHeaderMenuComponent implements OnInit {
  faBell = faBell;
  faUserCircle = faUserCircle;
  faSignOutAlt = faSignOutAlt;
  faBars = faBars;
  faTimes = faTimes;

  user: User;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
  }

  logout(): Promise<boolean> {
    this.authService.logout();
    return this.router.navigate(['/auth']);
  }
}
