import { Plate } from './plate';
import { Establishment } from './establishments';
import { MachineDTO } from '../interfaces';

export class Machine {
  id: string;
  code: string;
  modelName: string;
  deviceId: string;
  plate?: Plate;
  establishment?: Establishment;
  establishmentId?: string;
  valueNotCollect: number;
  valueCollect: number;
  valueCollectComingSoon?: number;
  maxHopper: number;
  minHopper: number;
  totalValue: number;
  creditValue: number;
  hopperCapacity: number;
  mediumPercentage: number;
  minimPercentage: number;

  constructor() {
    this.id = '';
    this.code = '';
    this.modelName = '';
    this.plate = null;
    this.establishment = null;
    this.establishmentId = '';
    this.valueNotCollect = null;
    this.valueCollect = null;
    this.valueCollectComingSoon = null;
    this.totalValue = null;
    this.creditValue = null;
    this.hopperCapacity = null;
    this.mediumPercentage = null;
    this.minimPercentage = null;
  }

  setEstablishment(establishment: Establishment): void {
    if (establishment?.id === this.establishmentId) {
      this.establishment = establishment;
    }
  }

  setPlate(plate: Plate): void {
    if (plate?.deviceId === this.deviceId) {
      this.plate = plate;
    }
  }

  deserialize(input: MachineDTO): Machine {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.code = input.code;
    this.modelName = input.modelName;
    this.deviceId = input.deviceId;
    // this.plate = input.plate;
    this.establishment = null;
    this.establishmentId = input.establishmentId;
    this.valueNotCollect = input.noCollectThreshold;
    this.valueCollect = input.collectThreshold;
    // this.valueCollectComingSoon = input.valueCollectComingSoon;
    this.totalValue = input.creditValue;
    this.creditValue = input.creditValue;
    this.hopperCapacity = input.hopperCapacity;
    // hopperCapacity: number; // hopperCapacity
    this.minHopper = input.hopperAtRiskThreshold;
    this.maxHopper = input.hopperFailingThreshold;
    this.mediumPercentage = input.winningFraudRiskThreshold;
    this.minimPercentage = input.winningFraudFailThreshold;

    return this;
  }

  deserializeArray(inputArray: MachineDTO[]): Machine[] {
    const machines: Array<Machine> = inputArray.map((input) => new Machine().deserialize(input));
    return machines;
  }
}
