import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { conditionalValidator, PlateService } from 'app/modules/dashboard/services';
import { DisplayFieldCSSI } from 'app/shared/interfaces';
import {
  NewUserProps,
  OperatorCompany,
  typeOperatorCompanyUserRoleOptions,
  TypeUserRole,
  typeUserRoleOptions,
  User,
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-form-add-user',
  styleUrls: ['./app-form-add-user.component.scss'],
  templateUrl: './app-form-add-user.component.html',
})
export class AppFormAddUserComponent implements OnInit, OnChanges {
  faTimes = faTimes;

  @Input() user: User;
  @Input() isBackOfficeUser = false;
  @Input() allowedRole: TypeUserRole;
  @Input() isEditingUser = false;
  @Output() save: EventEmitter<NewUserProps> = new EventEmitter<NewUserProps>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  formGroup: FormGroup;
  fullName: FormControl;
  // operatorCompany: FormControl;
  name: FormControl;
  lastName: FormControl;
  email: FormControl;
  password: FormControl;
  rePassword: FormControl;
  role: FormControl;
  orgRole: FormControl;

  roleOptions = typeUserRoleOptions;

  orgRoleOptions = typeOperatorCompanyUserRoleOptions;
  TypeUserRole = TypeUserRole;

  constructor(private plateService: PlateService) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.patchFormValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { user } = changes;
    if (user && user.currentValue) {
      this.patchFormValues();
    }
  }

  createFormGroup(): void {
    this.name = new FormControl('', Validators.required.bind(this));
    this.lastName = new FormControl('', Validators.required.bind(this));
    this.email = new FormControl('', Validators.required.bind(this));
    this.password = new FormControl('', [
      conditionalValidator(() => !this.isEditingUser, Validators.required.bind(this)),
      conditionalValidator(() => !this.isEditingUser, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,})')),
    ]);
    this.rePassword = new FormControl('', [
      conditionalValidator(() => !this.isEditingUser, Validators.required.bind(this)),
      conditionalValidator(() => !this.isEditingUser, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,})')),
    ]);
    this.role = new FormControl(
      {
        value: this.allowedRole,
        disabled: this.isBackOfficeUser,
      },
      Validators.required.bind(this),
    );
    this.orgRole = new FormControl(null, [
      conditionalValidator(() => this.role.value === TypeUserRole.CLIENT, Validators.required.bind(this)),
    ]);
    this.fullName = new FormControl('', [
      // conditionalValidator(() => this.isBackOfficeUser, Validators.required.bind(this)),
    ]);
    // this.operatorCompany = new FormControl(null, [
    //   conditionalValidator(() => this.isBackOfficeUser, Validators.required.bind(this)),
    // ]);

    if (this.user?.email) {
      this.email.disable();
    }

    this.formGroup = new FormGroup(
      {
        name: this.name,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        rePassword: this.rePassword,
        role: this.role,
        orgRole: this.orgRole,
        fullName: this.fullName,
        // operatorCompany: this.operatorCompany,
      },
      this.passwordAndRepasswordAreEqual('password', 'rePassword'),
    );
  }

  private patchFormValues(): void {
    if (!this.user || this.user.id === '' || !this.formGroup) {
      return;
    }

    // let nameInitial = '';
    // if (!this.isBackOfficeUser && this.user) {
    //   nameInitial = this.user.firstName;
    // }

    this.name.patchValue(this.user.firstName || '');
    this.lastName.patchValue(this.user.lastName || '');
    this.email.patchValue(this.user.email);
    this.role.patchValue(this.user.role);
    this.orgRole.patchValue(this.user.orgRole);
    this.fullName.patchValue(this.user.firstAndLastName);
    // this.operatorCompany.patchValue(this.user.operatorCompany);
  }

  saveFn(): void {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    this.save.emit({
      ...this.user,
      firstName: this.name.value,
      lastName: this.lastName.value,
      role: this.role.value,
      email: this.email.value,
      password: this.password.value,
      orgRole: this.orgRole.value,
    });
  }

  cancelFn(): void {
    this.cancel.emit();
  }

  searchOperatingCompany = (text$: Observable<string>): Observable<Array<OperatorCompany>> =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((searchText) => {
        if (searchText.length >= 1) {
          return this.plateService
            .getOperantingCompanies(0, 9999, { text: searchText })
            .then((response) => response.operatingCompanies);
        }

        return [];
      }),
    );

  /**
   * Used to format the result data from the lookup into the
   * display and list values. Maps `{name: "band", id:"id" }` into a string
   */
  resultFormatBandListValue(value: OperatorCompany): string {
    return `${value.name}`;
  }

  /**
   * Initially binds the string value and then after selecting
   * an item by checking either for string or key/value object.
   */
  inputFormatBandListValue(value: OperatorCompany): string {
    if (value) {
      this.user.operatorCompany = value;

      return `${value.name}`;
    }

    return '';
  }

  clearSearch(): void {
    this.user.operatorCompany = null;
  }

  passwordAndRepasswordAreEqual(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.passwordAndRepasswordEqual) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ passwordAndRepasswordEqual: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCss(field: FormControl): DisplayFieldCSSI {
    return {
      'is-invalid': this.isFieldInvalid(field),
      'is-valid': this.isFieldValid(field),
    };
  }

  isFieldValid(field: FormControl): boolean {
    return field.valid && field.dirty;
  }

  isFieldInvalid(field: FormControl): boolean {
    return field.invalid && field.dirty;
  }
}
