import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { NewUserProps, User } from 'app/shared/models';
import { NkmApiResponse, UserDTO } from 'app/shared/interfaces';

export interface OperatorCompaniesUsersRequestProps {
  operatorCompanyId: string;
  role?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UsersByOperatorCompanyService {
  private readonly apiUrl = `${environment.apiUrl}/client/organizations`;

  constructor(private http: HttpClient) {}

  async getOperatorCompanyUsers(props: OperatorCompaniesUsersRequestProps): Promise<User[]> {
    const { operatorCompanyId, role } = props;
    const url = `${this.apiUrl}/${operatorCompanyId}/users`;
    let params = {};

    if (role) {
      params = { role };
    }

    const response = await this.http.get<NkmApiResponse<{ orgUsers: UserDTO[] }>>(url, { params }).toPromise();

    const {
      data: { orgUsers },
    } = response;

    return new User().deserializeArray(orgUsers);
  }

  async saveUser(user: NewUserProps, organizationId: string, userId: string): Promise<void> {
    if (environment.useMocks) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 300);
      });
    }

    if (userId) {
      return this.editUser(user, userId, organizationId);
    }

    return this.createUser(user, organizationId);
  }

  async createUser(user: NewUserProps, organizationId: string): Promise<void> {
    const url = `${this.apiUrl}/${organizationId}/users`;
    await this.http
      .post<{ user: UserDTO }>(url, {
        ...user,
        role: user.orgRole,
      })
      .toPromise();
  }

  async editUser(user: NewUserProps, userId: string, organizationId: string): Promise<void> {
    const url = `${this.apiUrl}/${organizationId}/users/${userId}`;
    await this.http
      .put<{ user: UserDTO }>(url, {
        ...user,
        orgRole: user.orgRole,
      })
      .toPromise();
  }
}
