export interface MachineDTO {
  id: string;
  code: string;
  modelName: string;
  deviceId?: string;
  organizationId?: string;
  establishmentId?: string;
  slotRawId: string;
  creditValue: number;
  collectThreshold: number; // valueCollect
  noCollectThreshold: number; // valueNotCollect
  hopperCapacity: number; // hopperCapacity
  hopperAtRiskThreshold: number; // minHopper
  hopperFailingThreshold: number; // maxHopper
  winningFraudRiskThreshold: number; // mediumPercentage
  winningFraudFailThreshold: number; // minimPercentage
}
