import { OperatorCompany } from 'app/shared/models/operatorCompany';
import { Serializable } from 'app/core/interfaces';
import { OperatorCompanyDTO } from 'app/shared/interfaces';

import { TypeOperatorCompanyUserRole } from '../enums';

export enum TypeUserRole {
  ADMINISTRATOR = 'admin',
  CLIENT = 'client',
}
export const typeOperatorCompanyUserRoleOptions = Object.entries(TypeOperatorCompanyUserRole).map(([key, value]) => ({
  label: key,
  value,
  translateKey: `TYPE_ROLE.${value.replace(/ /g, '_').toUpperCase()}`,
}));

export const typeUserRoleOptions = Object.entries(TypeUserRole).map(([key, value]) => ({
  label: key,
  value,
  translateKey: `TYPE_ROLE.${value.replace(/ /g, '_').toUpperCase()}`,
}));

export enum TypeUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const userStatusOptions = Object.entries(TypeUserStatus).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));

export interface UserProps {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  orgRole?: TypeOperatorCompanyUserRole;
  role: 'client' | 'admin';
  operatorCompany?: OperatorCompanyDTO;
  password?: string;
}

export class User implements Serializable<User> {
  id: string;
  role: TypeUserRole;
  email: string;
  firstName: string;
  lastName: string;
  status: TypeUserStatus; // ???
  operatorCompany?: OperatorCompany;
  orgRole?: TypeOperatorCompanyUserRole;

  constructor(role?: TypeUserRole) {
    this.id = '';
    this.role = role || TypeUserRole.CLIENT;
    this.email = '';
    this.firstName = '';
    this.lastName = '';
  }

  get firstAndLastName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get bulletLetter(): string {
    const [firstLetter] = this.firstName || this.email;
    return firstLetter;
  }

  get bulletColor(): string {
    const normalizeHash = (hash: number, min: number, max: number) => Math.floor((hash % (max - min)) + min);
    const getHashOfString = (str: string) => {
      const charArray = Array.from(str);
      return charArray.reduce((total: number, _char, index) => {
        const code: number = str.charCodeAt(index);
        return total + code * index;
      }, 0);
    };

    const rgbRange = [0, 255];

    const hashFirstName = getHashOfString(this.firstName);
    const hashLastName = getHashOfString(this.lastName);
    const hashEmail = getHashOfString(this.email);
    const r = normalizeHash(hashFirstName, rgbRange[0], rgbRange[1]);
    const g = normalizeHash(hashLastName, rgbRange[0], rgbRange[1]);
    const b = normalizeHash(hashEmail, rgbRange[0], rgbRange[1]);
    return `rgb(${r},${g},${b})`;
  }

  isOperatorCompanyRole(rol: TypeOperatorCompanyUserRole): boolean {
    return this.orgRole === rol;
  }

  deserialize(input: UserProps): User {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.role = (input.role as TypeUserRole) || TypeUserRole.CLIENT;
    this.email = input.email || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.status = TypeUserStatus.ACTIVE;

    if (input.operatorCompany) {
      this.operatorCompany = new OperatorCompany().deserialize(input.operatorCompany);
    }

    this.orgRole = input.orgRole;

    return this;
  }

  deserializeArray(inputArray: Array<UserProps>): Array<User> {
    const usersArray: Array<User> = inputArray.map((input) => new User().deserialize(input));
    return usersArray;
  }
}
export interface NewUserProps {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  role: TypeUserRole | 'admin' | 'client';
  orgRole?: TypeOperatorCompanyUserRole;
}
