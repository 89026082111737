import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HarvestDTO } from 'app/shared/interfaces';
import { Collection, Fault, TelemetryMachine } from 'app/shared/models';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  private readonly apiUrl = `${environment.apiUrl}/client/slot-harvests`;

  constructor(private http: HttpClient) {}

  async addCollection(collection: Collection, telemetryMachine: TelemetryMachine): Promise<void> {
    if (environment.useMocks) {
      return;
    }

    const { machine, telemetryDetail } = telemetryMachine;
    const harvest: Partial<HarvestDTO> = {
      slotMachineId: machine.id,
      timestamp: new Date(),
      hopperAmountAfter: telemetryDetail.hopperMoney,
      moneyWithdrawn: collection.moneyWithdrawn,
    };
    await this.http.post<{ data: HarvestDTO }>(this.apiUrl, harvest).toPromise();
  }

  async getLastCollection(machineId: string): Promise<Date | null> {
    if (environment.useMocks) {
      return new Date();
    }

    try {
      const {
        data: { slotHarvest },
      } = await this.http.get<{ data: { slotHarvest: HarvestDTO } }>(`${this.apiUrl}/${machineId}/last`).toPromise();
      return new Date(slotHarvest.timestamp);
    } catch (err) {
      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async addFault(fault: Fault): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }
}
