import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { NewUserProps, TypeUserRole, User } from 'app/shared/models';
import { ApiPaginatedData, NkmApiResponse, PaginatedData, UserDTO } from 'app/shared/interfaces';
import { USERS_MOCK } from 'app/shared/mocks/user-mock';
import { TypeOperatorCompanyUserRole } from 'app/shared/enums';

export interface UsersRequestProps {
  offset?: number;
  limit?: number;
  // ---
  role?: TypeUserRole;
  name?: string;
}

export interface CreateUserProps {
  email: string;
  role: TypeUserRole;
  firstName: string;
  lastName: string;
  password: string;
}

export type CreateOperatorCompanyUserProps = Omit<CreateUserProps, 'role'> & {
  role: TypeOperatorCompanyUserRole;
  operatorCompanyId: string;
};

export interface UpdateUserProps {
  userId: string;
  role?: TypeUserRole;
  firstName?: string;
  lastName?: string;
  password?: string;
  // -----
  avatar?: string;
  emailValidatedAt?: Date;
  disabledAt?: Date;
}

export type UsersPaginated = PaginatedData<{ users: User[] }>;

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // GET USERS LIST
  async getUsers(props: UsersRequestProps): Promise<UsersPaginated> {
    const url = `${this.apiUrl}/admin/users`;
    const { offset, limit, ...filters } = props;
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      ...filters,
    };

    const response = await this.http.get<NkmApiResponse<ApiPaginatedData<UserDTO[]>>>(url, { params }).toPromise();

    const {
      data: { results, ...pagination },
    } = response;

    return {
      info: pagination,
      users: new User().deserializeArray(results),
    };
  }

  // GET USER
  async getUser(userId: string): Promise<User> {
    const url = `${this.apiUrl}/admin/users/${userId}`;

    const response = await this.http.get<NkmApiResponse<{ user: UserDTO }>>(url).toPromise();
    const {
      data: { user },
    } = response;

    return new User().deserialize(user);
  }

  // CREATE USER
  async createUser(props: NewUserProps): Promise<{ userId: string }> {
    const url = `${this.apiUrl}/admin/users`;
    const response = await this.http.post<NkmApiResponse<{ user: UserDTO }>>(url, props).toPromise();
    const {
      data: {
        user: { id },
      },
    } = response;
    return { userId: id };
  }

  // UPDATE USER
  async updateUser(props: UpdateUserProps): Promise<User> {
    const { userId, ...bodyParams } = props;
    const url = `${this.apiUrl}/admin/users/${userId}`;
    const body = {
      ...bodyParams,
    };
    const response = await this.http.put<NkmApiResponse<{ user: UserDTO }>>(url, body).toPromise();
    const {
      data: { user },
    } = response;
    return new User().deserialize(user);
  }

  // ! NOT IMPLEMENTED ON BACKEND
  // DELETE USER
  async deleteUser(userId: string): Promise<void> {
    const url = `${this.apiUrl}/admin/users/${userId}`;

    await this.http.delete(url).toPromise();
  }

  // CREATE USER ON OPERATOR COMPANY
  async createUserOnOperatorCompany(props: CreateOperatorCompanyUserProps): Promise<{ userId: string }> {
    const { operatorCompanyId, ...bodyParams } = props;
    const body = {
      ...bodyParams,
    };
    const url = `${this.apiUrl}/admin/organizations/${operatorCompanyId}/users`;
    const response = await this.http.post<NkmApiResponse<{ user: UserDTO }>>(url, body).toPromise();
    const {
      data: {
        user: { id },
      },
    } = response;
    return { userId: id };
  }

  async getBOUser(id: string): Promise<User> {
    const [user] = environment.useMocks
      ? new User().deserializeArray(USERS_MOCK).filter((userFilter) => userFilter.id === id)
      : [];
    return user;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async deactivateUser(userId: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }
}
