<div class="dropdown">
  <select
    class="form-control"
    [(ngModel)]="input"
    (ngModelChange)="onChange($event)"
    [compareWith]="compareFn"
    [disabled]="disabled"
  >
    <option [ngValue]="null">{{ placeholder }}</option>
    <option *ngFor="let option of options" [ngValue]="option.value">
      {{ option.translateKey ? (translatePrefix + option.translateKey | translate) : option.label }}
    </option>
  </select>
</div>
