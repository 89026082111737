export interface SlotCounterDTO {
  slotMachineId: string;
  timestamp30m: number;
  deviceId: string;
  credits_played: number;
  credits_won: number;
  bill_credits: number;
  hand_pay_credits: number;
  games_played: number;
  money_in: number;
  money_out: number;
  money_in_cycle: number;
  money_out_cycle: number;
  timestamp: Date;
}
