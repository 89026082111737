import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-p404-page',
  templateUrl: './p404-page.component.html',
  styleUrls: ['./p404-page.component.scss'],
})
export class P404PageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
