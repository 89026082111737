import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, timer } from 'rxjs';

import { SlotCounter, SlotCounterDTO } from 'app/shared/interfaces';
import { SLOT_COUNTERS_MOCK } from 'app/shared/mocks/slot-counters-mock';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SlotCounterService {
  private readonly TIME_TO_LOAD_SLOT_COUNTER = 1 * 60 * 1000; // milliseconds
  private readonly apiUrl = `${environment.apiUrl}/client/slot-counters`;

  private readonly slotCounterListSubject = new BehaviorSubject<Array<SlotCounter>>([]);
  readonly slotCounter$ = this.slotCounterListSubject.asObservable();

  private callSlotCounter = false;

  constructor(private http: HttpClient) {}

  get slotCounterList(): Array<SlotCounter> {
    return this.slotCounterListSubject.getValue();
  }

  setSlotCounterListSubject(slotCounterList: Array<SlotCounter>): void {
    this.slotCounterListSubject.next(slotCounterList);
  }

  stopCallSlotCounter(): void {
    this.callSlotCounter = false;
  }

  startCallSlotCounter(): void {
    if (this.callSlotCounter) {
      return;
    }
    this.callSlotCounter = true;
    this.getSlotCounters();
  }

  async getSlotCounters(): Promise<Array<void>> {
    if (environment.useMocks) {
      this.setSlotCounterListSubject(SLOT_COUNTERS_MOCK);
      return;
    }

    const {
      data: { results },
    } = await this.http.get<{ data: { results: SlotCounterDTO[] } }>(`${this.apiUrl}/me`).toPromise();

    const counters = results.map(({ slotMachineId, timestamp }) => ({
      slotMachineId,
      timestamp,
    }));

    this.setSlotCounterListSubject(counters);

    await timer(this.TIME_TO_LOAD_SLOT_COUNTER).toPromise();
    if (this.callSlotCounter) {
      this.getSlotCounters();
    }
  }
}
