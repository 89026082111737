export enum TypeToCollect {
  Yes = 'Yes',
  No = 'No',
  Medium = 'Medium',
}

export const typeToCollectOptions = Object.entries(TypeToCollect).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));
