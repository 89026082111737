<pagination-template #p="paginationApi" (pageChange)="change($event)" [id]="config.id">
  <div class="container-pagination font-size-lg color-primary-blue">
    <div class="pagination-previous item-pagination" [class.disabled]="p.isFirstPage()">
      <fa-icon class="nav-icon" [icon]="faChevronLeft" (click)="p.previous()"></fa-icon>
    </div>
    <div
      *ngFor="let page of p.pages"
      [class.current]="p.getCurrent() === page.value"
      class="item-pagination"
    >
      <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        <span>{{ page.label }}</span>
      </a>
      <div *ngIf="p.getCurrent() === page.value">
        <span>{{ page.label }}</span>
      </div>
    </div>

    <div class="pagination-next item-pagination" [class.disabled]="p.isLastPage()">
      <fa-icon class="nav-icon" [icon]="faChevronRight" (click)="goToNextPageFn(p)"></fa-icon>
    </div>

    <div class="d-flex align-items-center" [formGroup]="formPagination">
      <span class="color-primary-grey ms-4 me-3">{{ 'PAGINATION.GO_TO' | translate }}</span>
      <input type="text" class="col-md-2 me-3 form-control" [formControl]="goToPage" />
      <span (click)="goToPageFn(p)" class="cursor-pointer">
        {{ 'PAGINATION.GO' | translate }}
        <fa-icon class="nav-icon" [icon]="faChevronRight"></fa-icon
      ></span>
    </div>
  </div>
</pagination-template>
