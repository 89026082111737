<nav class="navbar navbar-expand-lg px-0">
  <fa-icon
    class="sidebar-toggler-open ps-4 font-size-xl"
    [icon]="faBars"
    appSidebarToggler
  ></fa-icon>
  <fa-icon
    class="sidebar-toggler-close show ps-4 font-size-xl"
    [icon]="faTimes"
    appSidebarToggler
  ></fa-icon>
  <a routerLink="/" class="align-items-center title-logo">
    <span class="font-primary-bold color-primary-white">AWIN</span>
    <span class="color-primary-white">Telemetry</span>
  </a>
  <div>
    <ul class="nav right-nav">
      <li class="nav-item">
        <div class="btn-group" ngbDropdown>
          <button
            type="button"
            class="btn btn-logout dropdown-toggle user-menu-btn"
            data-toggle="dropdown"
            ngbDropdownToggle
          >
            <fa-icon
              class="user-menu-icon font-size-xxxl color-primary-white"
              [icon]="faUserCircle"
            ></fa-icon>
            <div class="user-menu-text ms-1">
              <span class="user-menu-name">{{ user.firstName }} {{ user.lastName }}</span>
              <span class="user-menu-role">{{
                'TYPE_ROLE.' + user.orgRole | translate | uppercase
              }}</span>
            </div>
          </button>
          <div class="dropdown-menu dropdown-user-menu" aria-labelledby="dropdown" ngbDropdownMenu>
            <div class="user-menu">
              <fa-icon class="user-menu-icon font-size-xxxl mx-1" [icon]="faUserCircle"></fa-icon>
              <div class="user-menu-text d-flex flex-column">
                <span class="user-menu-name">{{ user.firstName }} {{ user.lastName }}</span>
                <span class="user-menu-role">{{
                  'TYPE_ROLE.' + user.orgRole | translate | uppercase
                }}</span>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" (click)="logout()">
              {{ 'Log out' | translate }}
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
