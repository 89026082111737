import { EstablishmentDTO } from 'app/shared/interfaces/dto';

export const ESTABLISHMENTS_MOCK: EstablishmentDTO[] = [
  {
    id: 'III828928',
    code: 'III828928',
    name: 'Bar Celona',
    address: 'Numancia 73, 5D, 08029 Barcelona',
    // machines: [MACHINES_MOCK[0]],
    postalCode: '00000',
    town: 'Barcelona',
  },
  {
    id: 'III828929',
    code: 'III828929',
    name: 'Bar Celona - 1',
    address: 'Numancia 73, 5D, 08029 Barcelona',
    // machines: [MACHINES_MOCK[1], MACHINES_MOCK[2]],
    postalCode: '00000',
    town: 'Barcelona',
  },
  {
    id: 'III828930',
    code: 'III828930',
    name: 'Bar Celona - 2',
    address: 'Numancia 73, 5D, 08029 Barcelona',
    // machines: [],
    postalCode: '00000',
    town: 'Barcelona',
  },
];
