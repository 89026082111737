export enum TypeStateCollection {
  Collect = 'Collect',
  ComingSoon = 'Coming Soon',
  NotCollect = 'Not Collect',
}
export const stateCollectionOptions = Object.entries(TypeStateCollection).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));
