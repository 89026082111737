import { Injectable } from '@angular/core';
import { AuthService } from 'app/modules/auth/services';
import { TypeOperatorCompanyUserRole } from 'app/shared/enums';
import { Action } from 'app/shared/interfaces';
import { UserActionsUtils } from 'app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  constructor(private authService: AuthService) {}

  private getUserActions(): Action[] {
    const { orgRole } = this.authService.getCurrentUser();
    return UserActionsUtils.getActionsUserByRol(orgRole);
  }

  isCollector(): boolean {
    const { orgRole } = this.authService.getCurrentUser();
    return orgRole === TypeOperatorCompanyUserRole.COLLECTOR;
  }

  hasPermission(action: Action): boolean {
    return UserActionsUtils.hasAction(action, this.getUserActions());
  }

  hasAllPermissions(actions: Action[]): boolean {
    return actions.every((act) => UserActionsUtils.hasAction(act, this.getUserActions()));
  }

  hasOnePermission(actions: Action[]): boolean {
    return actions.some((act) => UserActionsUtils.hasAction(act, this.getUserActions()));
  }
}
