import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tooltip-selected-items',
  styleUrls: ['./app-tooltip-selected-items.component.scss'],
  template: `<div class="container-tooltip-selected-items">
    <div class="tooltip-selected-items">
      <span class="mx-2 font-primary-light">{{ itemsChecked.length }} {{ 'ITEMS_SELECTED' | translate }}</span>
      <span class="font-primary-medium cursor-pointer" (click)="delete.emit()">
        <fa-icon [icon]="faTrash"></fa-icon> {{ 'BUTTON.DELETE' | translate }}
      </span>
    </div>
  </div> `,
})
export class AppTooltipSelectedItemsComponent implements OnInit {
  faTrash = faTrash;
  @Input() itemsChecked: string[];
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
