import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faArrowUpRightFromSquare, faCheckCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectOption } from 'app/shared/interfaces';
import { Plate } from 'app/shared/models';

@Component({
  selector: 'app-link-unlink-plate',
  templateUrl: './app-link-unlink-plate.component.html',
  styleUrls: ['./app-link-unlink-plate.component.scss'],
})
export class AppLinkUnlinkPlateComponent implements OnInit, OnChanges {
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faTrash = faTrash;
  faPlus = faPlus;
  faCheckCircle = faCheckCircle;

  @Input() plate: Plate | undefined;
  @Input() platesOptions: SelectOption[];
  @Output() unlinkPlate = new EventEmitter<void>();
  @Output() linkPlate = new EventEmitter<string>();

  formGroup: FormGroup;
  plateForm: FormControl;

  @ViewChild('confirmUnlinkPlate') confirmUnlinkPlate: TemplateRef<HTMLElement>;
  @ViewChild('successfullyLinkedPlate') successfullyLinkedPlate: TemplateRef<HTMLElement>;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.plateForm = new FormControl(this.plate);

    this.formGroup = new FormGroup({
      plate: this.plateForm,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { plate } = changes;
    if (plate && !plate.isFirstChange()) {
      this.plateForm.setValue(this.plate);
      if (plate.currentValue) {
        this.openSuccessfullyLinkedPlate();
      }
    }
  }

  openConfirmationUnlinkedPlateModal(): void {
    this.modalService.open(this.confirmUnlinkPlate, { size: 'md' });
  }

  openSuccessfullyLinkedPlate(): void {
    // this.modalService.open(this.successfullyLinkedPlate, { size: 'md' });
  }
}
