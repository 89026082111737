import { UserDTO } from '../interfaces';

// const [OP1, OP2] = OPERATING_COMPANIES_MOCK;

export const USER_MOCK: UserDTO = {
  id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
  email: 'rtarre@nakima.es',
  firstName: 'Robert',
  lastName: 'Tarré',
  // role: 'admin',
  role: 'admin',
  // collections: [],
  // operatorCompany: OP1,
  // token: 'q8IZ3kqSL362aCsc7YoH9vl626QxP5',
  // financialAdminId: null,
};

export const USER_MOCK_COLLECTOR: UserDTO = {
  id: '9ca7b23f-05fd-4140-b463-d04be0589c09',
  email: 'amacia@nakima.es',
  firstName: 'Andrea',
  lastName: 'Macia',
  role: 'client',
  // collections: [],
  // operatorCompany: OP2,
  // token: 'q8IZ3kqSL362aCsc7YoH9vl626QxP5',
  // financialAdminId: null,
};

export const USERS_MOCK: Array<UserDTO> = [USER_MOCK, USER_MOCK_COLLECTOR];
