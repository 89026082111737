type TaskName = 'harvest';

export interface WeeklyTaskDTO {
  id: string;
  organizationId: string;
  weekDay: number;
  taskName: TaskName;
  slotMachine?: string;
  establishmentId?: string;
  userId?: string;
}
