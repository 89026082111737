export { EstablishmentService } from './establishment.service';
export { PlateService } from './plate.service';
export { MachineService } from './machine.service';
export { TelemetryMachineService } from './telemetryMachine.service';
export { CollectionService } from './collection.service';
export { MachinePlateByEstablishmentService } from './machinePlateByEstablishment.service';
export { SlotCounterService } from './slotCounter.service';
export { UserActionService } from './userAction.service';
export { UsersByOperatorCompanyService } from './usersByOperatorCompany.service';
export { WeeklyTaskService } from './weeklyTask.service';
