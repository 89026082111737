import { SelectOption } from './selectOption';

export interface Pagination {
  count?: number;
  offset: number;
  limit: number;
}

export const PAGINATION_LIMIT = 15;

export const PAGINATION_OPTIONS: SelectOption[] = [
  {
    label: `${PAGINATION_LIMIT}`,
    value: PAGINATION_LIMIT,
  },
];

export interface PaginationI {
  offset?: number;
  limit: number;
}
