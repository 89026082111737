import { Serializable } from 'app/core/interfaces';
import { User } from 'app/shared/models/user';
import { OperatorCompanyDTO } from '../interfaces';

export enum TypeStateOperatorCompany {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum TypeSubscriptionOperatorCompany {
  Normal = 'Normal',
  Premium = 'Premium',
}
export class OperatorCompany implements Serializable<OperatorCompany> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  code: string;
  nif: string;

  owner?: User;

  userId?: string;
  subscription?: TypeSubscriptionOperatorCompany;
  email?: string;
  state?: TypeStateOperatorCompany;

  deserialize(input: OperatorCompanyDTO): OperatorCompany {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
    this.name = input.name;
    this.code = input.code;
    this.nif = input.nif;

    if (input.subscriptionCode) {
      this.subscription = input.subscriptionCode as TypeSubscriptionOperatorCompany;
    }

    this.owner = undefined;

    this.state = TypeStateOperatorCompany.Active;

    return this;
  }

  setOwner(owner: User): void {
    this.owner = owner;
  }

  deserializeArray(inputArray: Array<OperatorCompanyDTO>): Array<OperatorCompany> {
    const operatorCompaniesArray: Array<OperatorCompany> = inputArray.map((input) =>
      new OperatorCompany().deserialize(input),
    );
    return operatorCompaniesArray;
  }
}

export const stateOperatorCompanyOptions = Object.entries(TypeStateOperatorCompany).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));

export const typeOperatorCompanyOptions = Object.entries(TypeSubscriptionOperatorCompany).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));
