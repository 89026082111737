import { Injectable, Injector } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { AuthService } from 'app/modules/auth/services';
import { catchError, throttleTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private throttleLogout = new Subject();
  private authService: AuthService;

  constructor(private injector: Injector, private router: Router) {
    this.authService = this.injector.get(AuthService);
    this.throttleLogout.pipe(throttleTime(5000)).subscribe(() => {
      this.authService.logout();

      return this.router.navigate(['/auth/login']);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.authService.isAuthenticated()) {
      const token = this.authService.getCurrentToken();
      request = req.clone({
        setHeaders: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          this.throttleLogout.next();
        }

        return throwError(response);
      }),
    );
  }
}
