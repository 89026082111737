<div [formGroup]="formGroup">
  <div class="row mb-2">
    <div class="form-group col-md">
      <label for="name">{{ 'USERS.NAME' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="name"
        [formControl]="name"
        [placeholder]="'USERS.NAME' | translate"
      />
    </div>
    <div class="form-group col-md">
      <label for="lastName">{{ 'USERS.LAST_NAME' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="lastName"
        [formControl]="lastName"
        [placeholder]="'USERS.LAST_NAME' | translate"
      />
    </div>
    <div class="form-group col-md-12 mt-2">
      <label for="email">{{ 'USERS.EMAIL' | translate }}</label>
      <input
        type="text"
        class="form-control"
        id="email"
        [formControl]="email"
        [placeholder]="'USERS.EMAIL' | translate"
        [readonly]="isEditingUser"
      />
    </div>
    <div class="form-group col-md-12 mt-2" *ngIf="!isEditingUser">
      <label for="password">{{ 'USERS.PASSWORD' | translate }}</label>
      <input
        type="password"
        class="form-control"
        id="password"
        [formControl]="password"
        [placeholder]="'USERS.PASSWORD' | translate"
        [ngClass]="displayFieldCss(password)"
      />
      <div class="invalid-feedback d-block" *ngIf="password.hasError('pattern')">
        <p>
          {{ 'USERS.ERROR_PATTERN' | translate }}
        </p>
      </div>
    </div>
    <div class="form-group col-md-12 mt-2" *ngIf="!isEditingUser">
      <label for="rePassword">{{ 'USERS.RE_PASSWORD' | translate }}</label>
      <input
        type="password"
        class="form-control"
        id="rePassword"
        [formControl]="rePassword"
        [placeholder]="'USERS.PASSWORD' | translate"
        [ngClass]="displayFieldCss(rePassword)"
      />
      <div
        class="invalid-feedback d-block"
        *ngIf="rePassword.hasError('passwordAndRepasswordEqual')"
      >
        <p>
          {{ 'USERS.ERROR_PASSWORD_MATCH' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <!-- <ng-container *ngIf="isBackOfficeUser">
      <div class="form-group col-md">
        <label for="operatingCompany">
          {{ 'PLATE.OPERATING_COMPANY' | translate }}
        </label>
        <div class="input-group typeahead">
          <input
            type="text"
            class="form-control"
            id="operatingCompany"
            placeholder="{{ 'PLATE.SEARCH_OPERATING_COMPANY' | translate }}"
            [value]="inputFormatBandListValue(user?.operatorCompany)"
            [ngbTypeahead]="searchOperatingCompany"
            [resultFormatter]="resultFormatBandListValue.bind(this)"
            [inputFormatter]="inputFormatBandListValue.bind(this)"
          />
          <span
            class="input-group-append bg-white cursor-pointer"
            (click)="clearSearch()"
            *ngIf="user?.operatorCompany"
          >
            <span class="input-group-text bg-transparent">
              <fa-icon [icon]="faTimes"></fa-icon>
            </span>
          </span>
        </div>
      </div>
    </ng-container> -->
    <div class="form-group col-md" *ngIf="isBackOfficeUser">
      <label for="role">{{ 'USERS.ROLE' | translate }}</label>
      <app-select
        formControlName="role"
        [placeholder]="'USERS.SEARCH_ROLE' | translate"
        [options]="roleOptions"
        [disabled]="isBackOfficeUser"
      ></app-select>
    </div>
    <div class="form-group col-md" *ngIf="role.value === TypeUserRole.CLIENT">
      <label for="role">{{ 'USERS.ROLE' | translate }}</label>
      <app-select
        formControlName="orgRole"
        [placeholder]="'USERS.SEARCH_ROLE' | translate"
        [options]="orgRoleOptions"
      ></app-select>
    </div>
  </div>
  <div class="col-md-12 justify-content-end d-flex">
    <button class="btn-awinsoft secondary outline" (click)="cancelFn()" *ngIf="!isBackOfficeUser">
      {{ 'USERS.CANCEL' | translate }}
    </button>
    <button class="btn-awinsoft primary" (click)="saveFn()" [disabled]="formGroup.invalid">
      {{ 'USERS.SAVE' | translate }}
    </button>
  </div>
</div>
