export type ApiPaginatedData<T> = {
  offset: number;
  limit: number;
  count: number;
  results: T;
};

export type NkmApiResponse<T> = {
  code: number;
  message: string;
  timestamp: number;
  data: T;
};
