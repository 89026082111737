import { TypeSubscriptionOperatorCompany } from 'app/shared/models';

export interface OperatorCompanyDTO {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  code: string;
  nif: string;
  subscriptionCode?: TypeSubscriptionOperatorCompany;
}
