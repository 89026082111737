import { EstablishmentDTO } from '../interfaces/dto';

export class Establishment {
  id: string;
  code: string;
  postalCode: string;
  name: string;
  address: string;
  town: string;

  constructor() {
    this.id = '';
    this.code = '';
    this.postalCode = '';
    this.name = '';
    this.address = '';
    this.town = '';
  }

  deserialize(input: EstablishmentDTO): Establishment {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.code = input.code;
    this.postalCode = input.postalCode;
    this.name = input.name;
    this.address = input.address;
    this.town = input.town;

    return this;
  }

  deserializeArray(inputArray: EstablishmentDTO[]): Establishment[] {
    const establishments: Array<Establishment> = inputArray.map((input) => new Establishment().deserialize(input));
    return establishments;
  }
}
