import { KeyHopperLimit } from '../models';

const MAXIM_PERCENTAGE = 100;
const MEDIUM_PERCENTAGE = 50;
const MINIM_PERCENTAGE = 25;

interface ValueHopperLimit {
  minimPercentage: number;
  mediumPercentage: number;
  totalValue: number;
}
export class ValueHopperLimitUtil {
  public static get options(): { [key: string]: number } {
    return {
      OK: MAXIM_PERCENTAGE,
      '50%': MEDIUM_PERCENTAGE,
      '25%': MINIM_PERCENTAGE,
    };
  }

  public static get optionsKeys(): { [key: string]: number } {
    return {
      maximPercentage: MAXIM_PERCENTAGE,
      mediumPercentage: MEDIUM_PERCENTAGE,
      minimPercentage: MINIM_PERCENTAGE,
    };
  }

  public static getValuePercentage({ totalValue, mediumPercentage }: ValueHopperLimit): number {
    return (totalValue * mediumPercentage) / MAXIM_PERCENTAGE;
  }

  public static keyPercentageByAmount(
    { minimPercentage, mediumPercentage, totalValue }: ValueHopperLimit,
    amount: number,
  ): KeyHopperLimit {
    const value = (amount * MAXIM_PERCENTAGE) / totalValue;

    if (value <= minimPercentage) {
      return 'low';
    }
    if (value <= mediumPercentage) {
      return 'medium';
    }

    return 'high';
  }
}
