import { TelemetryDTO, TelemetryStateDTO } from '../interfaces';
import { PLATES_MOCK } from './plate-mock';

export const TELEMETRY_MOCK: TelemetryDTO[] = [
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[0].deviceId, // match
    amount: 200, // dinero a recaudar
    averageAmount: 400,
    percentageAwards: 20,
    lastCollection: 8,
    hopperMoney: 200,
  },
  {
    state: TelemetryStateDTO.DISCONNECTED,
    deviceId: PLATES_MOCK[1].deviceId,
    amount: 200,
    averageAmount: 400,
    percentageAwards: 20,
    lastCollection: 8,
    hopperMoney: 500,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[2].deviceId,
    amount: 200,
    averageAmount: 400,
    percentageAwards: 20,
    lastCollection: 8,
    hopperMoney: 800,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[3].deviceId,
    amount: 725.4,
    averageAmount: 652.3,
    percentageAwards: 68,
    lastCollection: 6,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[4].deviceId,
    amount: 489.2,
    averageAmount: 519.7,
    percentageAwards: 102,
    lastCollection: 6,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[5].deviceId,
    amount: 1028.3,
    averageAmount: 720.4,
    percentageAwards: 73,
    lastCollection: 5,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.DISCONNECTED,
    deviceId: PLATES_MOCK[6].deviceId,
    amount: 201.1,
    averageAmount: 346.8,
    percentageAwards: 71,
    lastCollection: 5,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[7].deviceId,
    amount: 405.6,
    averageAmount: 680.2,
    percentageAwards: 72,
    lastCollection: 5,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[8].deviceId,
    amount: -105.2,
    averageAmount: 340.2,
    percentageAwards: 76,
    lastCollection: 5,
    hopperMoney: 514,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[9].deviceId,
    amount: 802.3,
    averageAmount: 740.2,
    percentageAwards: 76,
    lastCollection: 6,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[10].deviceId,
    amount: 205.4,
    averageAmount: 523.4,
    percentageAwards: 72,
    lastCollection: 6,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[11].deviceId,
    amount: 305.8,
    averageAmount: 412.6,
    percentageAwards: 71,
    lastCollection: 6,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.DISCONNECTED,
    deviceId: PLATES_MOCK[12].deviceId,
    amount: 205.4,
    averageAmount: 804.8,
    percentageAwards: 72,
    lastCollection: 2,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[13].deviceId,
    amount: -402.6,
    averageAmount: 302.4,
    percentageAwards: 73,
    lastCollection: 2,
    hopperMoney: 317,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[14].deviceId,
    amount: 352.6,
    averageAmount: 725.4,
    percentageAwards: 74,
    lastCollection: 2,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[15].deviceId,
    amount: 580,
    averageAmount: 659.3,
    percentageAwards: 72,
    lastCollection: 4,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[16].deviceId,
    amount: 328.1,
    averageAmount: 390.4,
    percentageAwards: 73,
    lastCollection: 4,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[17].deviceId,
    amount: -302.1,
    averageAmount: 307.2,
    percentageAwards: 75,
    lastCollection: 3,
    hopperMoney: 317,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[18].deviceId,
    amount: 206.2,
    averageAmount: 462.5,
    percentageAwards: 72,
    lastCollection: 3,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.DISCONNECTED,
    deviceId: PLATES_MOCK[19].deviceId,
    amount: 605.2,
    averageAmount: 500.4,
    percentageAwards: 71,
    lastCollection: 5,
    hopperMoney: 620,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[20].deviceId,
    amount: 1420.8,
    averageAmount: 980.4,
    percentageAwards: 73,
    lastCollection: 5,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IDDLE,
    deviceId: PLATES_MOCK[21].deviceId,
    amount: 650.2,
    averageAmount: 723.4,
    percentageAwards: 74,
    lastCollection: 6,
    hopperMoney: 720,
  },
  {
    state: TelemetryStateDTO.IN_GAME,
    deviceId: PLATES_MOCK[22].deviceId,
    amount: 421.8,
    averageAmount: 380.6,
    percentageAwards: 72,
    lastCollection: 2,
    hopperMoney: 720,
  },
  // {
  //   state: TelemetryStateDTO.IDDLE,
  //   deviceId: PLATES_MOCK[1].deviceId,
  //   amount: 840.3,
  //   averageAmount: 729.1,
  //   percentageAwards: 73,
  //   lastCollection: 5,
  //   hopperMoney: 720,
  // },
  // {
  //   state: TelemetryStateDTO.IN_GAME,
  //   deviceId: PLATES_MOCK[1].deviceId,
  //   amount: 690.4,
  //   averageAmount: 428.6,
  //   percentageAwards: 71,
  //   lastCollection: 4,
  //   hopperMoney: 720,
  // },
  // {
  //   state: TelemetryStateDTO.IDDLE,
  //   deviceId: PLATES_MOCK[1].deviceId,
  //   amount: -475.9,
  //   averageAmount: 650.4,
  //   percentageAwards: 78,
  //   lastCollection: 4,
  //   hopperMoney: 244,
  // },
];
