import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-group-search',
  templateUrl: './app-group-search.component.html',
  styleUrls: ['./app-group-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppGroupSearchComponent),
      multi: true,
    },
  ],
})
export class AppGroupSearchComponent implements ControlValueAccessor {
  faSearch = faSearch;
  faTimes = faTimes;

  input: string;
  isInputSearchFocused = false;

  @Input() formControl: FormControl;
  @Input() placeholder: string;
  @Output() onClick = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onTouch: any = () => {};

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string): void {
    this.input = input;
  }

  onClickFn(): void {
    this.onClick.emit();
  }

  onCloseFn(): void {
    this.isInputSearchFocused = false;
  }
}
