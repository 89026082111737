import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Establishment, User } from 'app/shared/models';
import { ESTABLISHMENTS_MOCK } from 'app/shared/mocks/establishment-mock';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EstablishmentDTO } from 'app/shared/interfaces/dto';
import { AuthService } from 'app/modules/auth/services';
import { BehaviorSubject } from 'rxjs';
import { PaginationI } from 'app/shared/interfaces';

interface GetEstablishmentsProps {
  pagination?: PaginationI;
  forceRefresh?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  private readonly apiUrl = `${environment.apiUrl}/client/establishments`;
  private currentUser: User;

  private readonly TIME_TO_LOAD_ESTABLISHMENTS = 5 * 60 * 1000; // milliseconds
  private lastRefresEstablishments: Date;

  private readonly establishmentListSubject = new BehaviorSubject<Array<Establishment>>(null);
  readonly establishments$ = this.establishmentListSubject.asObservable();

  private readonly isRefreshingSubject = new BehaviorSubject<boolean>(false);
  readonly isRefreshing$ = this.isRefreshingSubject.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {
    this.currentUser = this.authService.getCurrentUser();
  }

  get establishmentList(): Array<Establishment> {
    return this.establishmentListSubject.getValue();
  }

  setEstablishmentListSubject(establishmentList: Array<Establishment>): void {
    this.establishmentListSubject.next(establishmentList);
  }

  private isTimeToRefreshEstablishments(): boolean {
    if (this.lastRefresEstablishments === undefined) return true;

    const now = new Date();
    const differenceBetweenTime = now.getTime() - this.lastRefresEstablishments.getTime();

    return differenceBetweenTime >= this.TIME_TO_LOAD_ESTABLISHMENTS;
  }

  private async loadEstablishments(pagination?: PaginationI): Promise<Array<Establishment>> {
    let params = new HttpParams();
    if (pagination) {
      const { limit } = pagination;
      params = params.append('limit', limit);
    }

    const {
      data: { results },
    } = await this.http.get<{ data: { results: EstablishmentDTO[] } }>(`${this.apiUrl}/me`, { params }).toPromise();
    return new Establishment().deserializeArray(results);
  }

  private async refreshEstablishments(pagination?: PaginationI): Promise<Array<Establishment>> {
    if (pagination !== undefined) {
      return this.loadEstablishments(pagination);
    }
    // if (this.isRefreshingSubject.getValue()) {
    //   const a = this.isRefreshing$
    //     .pipe(
    //       filter((isRefreshing: boolean) => isRefreshing === false),
    //       map(() => this.establishmentList),
    //     )
    //     .toPromise();
    //   return a;
    // }

    this.isRefreshingSubject.next(true);
    const establishments = await this.loadEstablishments(pagination);

    this.setEstablishmentListSubject(establishments);
    this.lastRefresEstablishments = new Date();
    return establishments;
  }

  async getEstablishments({ pagination, forceRefresh }: GetEstablishmentsProps = {}): Promise<Array<Establishment>> {
    if (environment.useMocks) {
      return new Establishment().deserializeArray(ESTABLISHMENTS_MOCK);
    }

    let establishments = this.establishmentList;
    if (forceRefresh || pagination || !this.establishmentList) {
      establishments = await this.refreshEstablishments(pagination);
    } else if (this.isTimeToRefreshEstablishments()) {
      this.refreshEstablishments(pagination);
    }
    return establishments;
  }

  async getEstablishment(id: string): Promise<Establishment> {
    let establishment: Establishment = this.establishmentList?.find((m) => m.id === id);

    if (!environment.useMocks && !establishment) {
      const {
        data: { establishment: establishmentDTO },
      } = await this.http.get<{ data: { establishment: EstablishmentDTO } }>(`${this.apiUrl}/${id}`).toPromise();
      establishment = new Establishment().deserialize(establishmentDTO);
    }

    return establishment;
  }

  async saveEstablishment(code: string, establishment: Establishment): Promise<Establishment> {
    let newEstablishment: Establishment;
    if (code) {
      newEstablishment = await this.editEstablishment(code, establishment);
    } else {
      newEstablishment = await this.createEstablishment(establishment);
    }
    await this.getEstablishments({ forceRefresh: true });

    return newEstablishment;
  }

  async editEstablishment(id: string, establishment: Establishment): Promise<Establishment> {
    let updatedEstablishment: EstablishmentDTO;

    if (environment.useMocks) {
      [updatedEstablishment] = ESTABLISHMENTS_MOCK;
    } else {
      ({ data: updatedEstablishment } = await this.http
        .put<{ data: EstablishmentDTO }>(`${this.apiUrl}/${id}`, { ...establishment })
        .toPromise());
    }
    return new Establishment().deserialize(updatedEstablishment);
  }

  async createEstablishment(establishment: Establishment): Promise<Establishment> {
    let newEstablishment: EstablishmentDTO;

    if (environment.useMocks) {
      [newEstablishment] = ESTABLISHMENTS_MOCK;
    } else {
      ({ data: newEstablishment } = await this.http
        .post<{ data: EstablishmentDTO }>(this.apiUrl, {
          ...establishment,
          organizationId: this.currentUser.operatorCompany.id,
        })
        .toPromise());
    }
    return new Establishment().deserialize(newEstablishment);
  }

  async deleteEstablishments(establishmentIds: string[]): Promise<void> {
    if (environment.useMocks) {
      this.establishmentList.filter((establishment) => !establishmentIds.includes(establishment.id));
      return;
    }
    try {
      await Promise.all(
        // eslint-disable-next-line array-callback-return
        establishmentIds.map(async (id) => {
          await this.http.delete(`${this.apiUrl}/${id}`).toPromise();
        }),
      );
      this.refreshEstablishments();
    } catch (error) {
      throw new Error('Error deleting establishment');
    }
  }
}
