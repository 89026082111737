import { TelemetryDTO, TelemetryStateDTO } from '../interfaces';

export interface PercentageValue {
  bulletColor: string;
  text: string;
}

export enum TypePercentageAwards {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum TypeStateTelemetryDetail {
  Connected = 'Connected',
  InGame = 'In Game',
  Disconnected = 'Disconnected',
  // Out of service => status: Connected but value hopper it's minim for machine
  OutOfService = 'Out of service',
}

export const stateTelemetryOptions = Object.entries(TypeStateTelemetryDetail).map(([key, value]) => ({
  label: key,
  value,
  translateKey: value.replace(/ /g, '_').toUpperCase(),
}));

export class TelemetryDetail {
  state: TypeStateTelemetryDetail;
  deviceId: string;
  amount: number;
  averageAmount: number;
  percentageAwards: number;
  hopperMoney: number;

  setStateToOutOfService(): void {
    this.state = TypeStateTelemetryDetail.OutOfService;
  }

  deserialize(input: TelemetryDTO): TelemetryDetail {
    if (!input) {
      return this;
    }

    switch (input.state) {
      case TelemetryStateDTO.IN_GAME:
        this.state = TypeStateTelemetryDetail.InGame;
        break;
      case TelemetryStateDTO.DISCONNECTED:
        this.state = TypeStateTelemetryDetail.Disconnected;
        break;
      case TelemetryStateDTO.IDDLE:
        this.state = TypeStateTelemetryDetail.Connected;
        break;
      case TelemetryStateDTO.UNKNOWN:
        this.state = TypeStateTelemetryDetail.Connected;
        break;
      default:
        this.state = TypeStateTelemetryDetail.OutOfService;
        break;
    }
    this.deviceId = input.deviceId;
    this.amount = input.amount;
    this.averageAmount = input.averageAmount;
    this.percentageAwards = input.percentageAwards;
    this.hopperMoney = input.hopperMoney;

    return this;
  }

  deserializeArray(inputArray: Array<TelemetryDTO>): Array<TelemetryDetail> {
    const telemetryDetails: Array<TelemetryDetail> = inputArray.map((input) =>
      new TelemetryDetail().deserialize(input),
    );

    return telemetryDetails;
  }
}
