import { TypeOperatorCompanyUserRole } from 'app/shared/enums';

export interface UserDTO {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  orgRole?: TypeOperatorCompanyUserRole;
  role: 'client' | 'admin';
}
