<div class="d-flex mb-4 align-items-center justify-content-between sidebar-header">
  <a routerLink="/" class="d-flex align-items-center title-logo">
    <span class="font-primary-bold color-primary-white">AWIN</span>
    <span class="color-primary-white">Telemetry</span>
  </a>
  <fa-icon
    class="sidebar-toggler-close color-primary-white font-size-xl show"
    [icon]="faTimes"
    appSidebarToggler
  ></fa-icon>
</div>
<app-sidebar-menu></app-sidebar-menu>
