import { SelectOption } from '../interfaces';
import { Establishment, Plate } from '../models';

export const CREDIT_VALUE_MACHINE = [1, 10, 20, 100];

export class SelectOptionUtils {
  public static platesOptions(plates: Plate[]): SelectOption[] {
    return plates.map((plate) => ({
      label: plate.deviceId,
      value: plate.deviceId,
    }));
  }

  public static establishmentsOptions(establishments: Establishment[]): SelectOption[] {
    return establishments.map((establishment) => ({
      label: establishment.name,
      value: establishment.id,
    }));
  }

  public static creditValueOptions(creditValues: number[]): SelectOption[] {
    return creditValues.map((value) => ({
      label: value.toString(),
      value,
    }));
  }
}
