import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';
import { UserActionService } from 'app/modules/dashboard/services';

@Injectable()
export class NoIsAdminLoggedGuard implements CanActivate {
  constructor(
    private location: Location,
    private router: Router,
    private authService: AuthService,
    private userActionService: UserActionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isAdminUser()) {
      this.router.navigate(['/bo-users']);
      return false;
    }

    if (this.userActionService.isCollector()) {
      return true;
    }

    const { actions } = route.data;
    if (this.userActionService.hasOnePermission(actions)) {
      return true;
    }

    this.location.back();
    return false;
  }
}
