import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'environments/environment';
import { OperatorCompany, User } from 'app/shared/models';

import { OperatorCompanyDTO, PaginatedData, NkmApiResponse, ApiPaginatedData, UserDTO } from 'app/shared/interfaces';
import { USERS_MOCK } from 'app/shared/mocks/user-mock';
import { TypeOperatorCompanyUserRole } from 'app/shared/enums';

interface OperatorCompaniesFilters {
  name?: string;
  code?: string;
  nif?: string;
  subscriptionCode?: string;
}
export interface OperatorCompaniesRequestProps {
  offset: number;
  limit: number;
  // ---
  filters?: OperatorCompaniesFilters;
}

interface OperatorCompanyCreateProps {
  ownerId: string; // ! backend no mandatory, but set mandatory
  name: string;
  code: string;
  nif: string;
}

export interface UserFilters {
  text?: string;
}

export interface OrganizationUserRoleRequestProp {
  operatorCompanyId: string;
  userId?: string;
  roles?: TypeOperatorCompanyUserRole[];
  role?: TypeOperatorCompanyUserRole;
}

export type OperatorCompaniesPaginated = PaginatedData<{ operatorCompanies: OperatorCompany[] }>;

@Injectable({
  providedIn: 'root',
})
export class OperatorCompanyAdminService {
  private readonly apiUrl = `${environment.apiUrl}/admin/organizations`;

  constructor(private http: HttpClient) {}

  async createOperatorCompany(props: OperatorCompanyCreateProps): Promise<OperatorCompany> {
    const url = `${this.apiUrl}`;
    const response = await this.http.post<NkmApiResponse<{ organization: OperatorCompanyDTO }>>(url, props).toPromise();
    const {
      data: { organization },
    } = response;
    return new OperatorCompany().deserialize(organization);
  }

  async getOperatorCompany(operatorCompanyId: string): Promise<OperatorCompany> {
    const url = `${this.apiUrl}/${operatorCompanyId}`;

    const response = await this.http.get<NkmApiResponse<{ organization: OperatorCompanyDTO }>>(url).toPromise();

    const {
      data: { organization },
    } = response;

    return new OperatorCompany().deserialize(organization);
  }

  async updateOperatorCompany(operatorCompany: OperatorCompany): Promise<OperatorCompany> {
    const url = `${this.apiUrl}/${operatorCompany.id}`;
    const props = {
      name: operatorCompany.name,
      code: operatorCompany.code,
      nif: operatorCompany.nif,
      subscriptionCode: operatorCompany.subscription,
    };
    const response = await this.http.put<NkmApiResponse<{ organization: OperatorCompanyDTO }>>(url, props).toPromise();
    const {
      data: { organization },
    } = response;
    return new OperatorCompany().deserialize(organization);
  }

  async getOperatorCompanies(props: OperatorCompaniesRequestProps): Promise<OperatorCompaniesPaginated> {
    const url = `${this.apiUrl}`;
    const { offset, limit, filters } = props;
    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters,
    };
    const response = await this.http
      .get<NkmApiResponse<ApiPaginatedData<OperatorCompanyDTO[]>>>(url, { params })
      .toPromise();

    const {
      data: { results, ...pagination },
    } = response;

    return {
      info: pagination,
      operatorCompanies: new OperatorCompany().deserializeArray(results),
    };
  }

  async getAllOperatorCompaniesMap(): Promise<{ allOperatorCompaniesMap: Map<string, OperatorCompany> }> {
    const { operatorCompanies } = await this.getOperatorCompanies({ limit: 0, offset: 0 });

    const operatorCompaniesMap: Map<string, OperatorCompany> = new Map<string, OperatorCompany>();
    operatorCompanies.forEach((operatorCompany) => {
      operatorCompaniesMap.set(operatorCompany.id, operatorCompany);
    });

    return {
      allOperatorCompaniesMap: operatorCompaniesMap,
    };
  }

  async deleteOperatorCompany(operatorCompanyId: string): Promise<void> {
    const url = `${this.apiUrl}/${operatorCompanyId}`;
    await this.http.delete(url).toPromise();
  }

  async getOperatorCompanyUsers(props: OrganizationUserRoleRequestProp): Promise<User[]> {
    const { orgUsers } = await this.getOperatorCompanyUsersDTO(props);

    return new User().deserializeArray(orgUsers);
  }

  async getOperatorCompanyUsersDTO(props: OrganizationUserRoleRequestProp): Promise<{ orgUsers: UserDTO[] }> {
    const { operatorCompanyId, roles = [] } = props;
    const url = `${this.apiUrl}/${operatorCompanyId}/users`;
    let params = new HttpParams();

    params = params.append('roles', roles.join());

    if (props.userId) {
      params = params.append('userId', props.userId);
    }

    try {
      const response = await this.http.get<NkmApiResponse<{ orgUsers: UserDTO[] }>>(url, { params }).toPromise();
      const {
        data: { orgUsers },
      } = response;

      return { orgUsers };
    } catch (err) {
      return { orgUsers: [] };
    }
  }

  async getOperatorCompaniesWithOwner(props: OperatorCompaniesRequestProps): Promise<OperatorCompaniesPaginated> {
    const { info, operatorCompanies } = await this.getOperatorCompanies(props);

    const operatorCompaniesUsersPromises: Array<Promise<{ orgUsers: UserDTO[] }>> = [];
    operatorCompanies.forEach(({ id }) => {
      operatorCompaniesUsersPromises.push(
        this.getOperatorCompanyUsersDTO({
          operatorCompanyId: id,
          roles: [TypeOperatorCompanyUserRole.OWNER],
        }),
      );
    });

    const result = await Promise.all(operatorCompaniesUsersPromises);

    operatorCompanies.forEach((op, index) => {
      const { orgUsers } = result[index];
      if (orgUsers.length === 0) {
        op.setOwner(undefined);
      }

      const [userInfo] = orgUsers;

      const owner = new User().deserialize(userInfo);
      op.setOwner(owner);
    });

    return {
      info,
      operatorCompanies,
    };
  }

  async getOperatorCompanyWithOwner(operatorCompanyId: string): Promise<OperatorCompany> {
    const operatorCompany = await this.getOperatorCompany(operatorCompanyId);
    try {
      const { orgUsers } = await this.getOperatorCompanyUsersDTO({
        operatorCompanyId,
        roles: [TypeOperatorCompanyUserRole.OWNER],
      });

      if (orgUsers.length === 0) {
        operatorCompany.setOwner(undefined);
        return operatorCompany;
      }

      const [userInfo] = orgUsers;

      const owner = new User().deserialize(userInfo);

      operatorCompany.setOwner(owner);
    } catch (error) {
      // TODO handle error properly
    }

    return operatorCompany;
  }

  async searchOperatorCompanyUsers(operatorCompanyId: string, filters?: UserFilters): Promise<{ users: User[] }> {
    const url = `${this.apiUrl}/${operatorCompanyId}/users`;
    const { text } = filters;
    if (!environment.useMocks) {
      const allUsers = new User().deserializeArray(USERS_MOCK);

      const users = allUsers.filter(({ firstName, lastName }) => {
        const fullName = firstName.concat(lastName);
        if (text !== undefined && !fullName.toLowerCase().includes(text.replace(' ', '').toLowerCase())) return false;

        return true;
      });

      if (users.length === 0) {
        throw new Error('No owners found');
      }
      return { users };
    }
    const response = await this.http.get<NkmApiResponse<{ users: UserDTO[] }>>(url).toPromise();
    const {
      data: { users },
    } = response;

    return { users: new User().deserializeArray(users) };
  }

  async editUserOrganizationRolesByAdmin(props: OrganizationUserRoleRequestProp): Promise<void> {
    const { operatorCompanyId, userId, roles } = props;
    const url = `${this.apiUrl}/${operatorCompanyId}/users/${userId}`;

    const newProps = {
      role: roles[0],
    };

    await this.http.put<NkmApiResponse<{ message: string }>>(url, newProps).toPromise();
  }
}
