import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Machine, User } from 'app/shared/models';
import { MACHINES_MOCK } from 'app/shared/mocks/machine-mock';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiPaginatedData, MachineDTO, NkmApiResponse, PaginationI } from 'app/shared/interfaces';
import { AuthService } from 'app/modules/auth/services';
import { filter, map } from 'rxjs/operators';

interface SaveMachineProps {
  code: string;
  modelName: string;
  deviceId: string;
  establishment: string;
  establishmentId: string;
  valueNotCollect: number;
  valueCollect: number;
  valueCollectComingSoon?: number;
  maxHopper: number;
  minHopper: number;
  totalValue: number;
  mediumPercentage: number;
  minimPercentage: number;
}

interface GetMachinesProps {
  pagination?: PaginationI;
  forceRefresh?: boolean;
}

export interface MachinesSearchProps {
  offset?: string;
  limit?: string;
  // ---
  organizationId?: string;
  code?: string;
  establishmentId?: string;
  deviceId?: string;
  model?: string;
  hopperCapacity?: number;
  // ---
  text?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MachineAdminService {
  private readonly apiUrl = `${environment.apiUrl}/admin/slot-machines`;

  private readonly TIME_TO_LOAD_MACHINES = 5 * 60 * 1000; // milliseconds
  private lastRefreshMachines: Date;

  private readonly machineListSubject = new BehaviorSubject<Array<Machine>>(null);
  readonly machines$ = this.machineListSubject.asObservable();

  private readonly isRefreshingSubject = new BehaviorSubject<boolean>(false);
  readonly isRefreshing$ = this.isRefreshingSubject.asObservable();

  private currentUser: User;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.currentUser = this.authService.getCurrentUser();
  }

  get machineList(): Array<Machine> {
    return this.machineListSubject.getValue();
  }

  setMachineListSubject(machineList: Array<Machine>): void {
    this.machineListSubject.next(machineList);
  }

  private isTimeToRefreshMachines(): boolean {
    if (this.lastRefreshMachines === undefined) return true;

    const now = new Date();
    const differenceBetweenTime = now.getTime() - this.lastRefreshMachines.getTime();

    return differenceBetweenTime >= this.TIME_TO_LOAD_MACHINES;
  }

  private async loadMachines(pagination?: PaginationI): Promise<Array<Machine>> {
    let params = new HttpParams();
    if (pagination) {
      const { limit } = pagination;
      params = params.append('limit', limit);
    }

    const {
      data: { results },
    } = await this.http.get<{ data: { results: MachineDTO[] } }>(`${this.apiUrl}/me`, { params }).toPromise();
    return new Machine().deserializeArray(results);
  }

  private async refreshMachines(pagination?: PaginationI): Promise<Array<Machine>> {
    if (pagination !== undefined) {
      return this.loadMachines(pagination);
    }
    if (this.isRefreshingSubject.getValue()) {
      return this.isRefreshing$
        .pipe(
          filter((isRefreshing: boolean) => isRefreshing === false),
          map(() => this.machineList),
        )
        .toPromise();
    }

    this.isRefreshingSubject.next(true);
    const machines = await this.loadMachines(pagination);

    this.setMachineListSubject(machines);
    this.lastRefreshMachines = new Date();

    return machines;
  }

  async unAssignPlateFromMachine(machineId: string): Promise<void> {
    const url = `${this.apiUrl}/${machineId}/unlink-device`;
    await this.http.put<{ data: null }>(url, {}).toPromise();
  }

  async searchMachines(props: MachinesSearchProps): Promise<Array<Machine>> {
    if (environment.useMocks) {
      return new Machine().deserializeArray(MACHINES_MOCK);
    }

    const { offset, limit, ...filters } = props;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      ...filters,
    };

    const {
      data: { results },
    } = await this.http.get<NkmApiResponse<ApiPaginatedData<MachineDTO[]>>>(`${this.apiUrl}/`, { params }).toPromise();
    return new Machine().deserializeArray(results);
  }

  async getMachines({ pagination, forceRefresh }: GetMachinesProps = {}): Promise<Array<Machine>> {
    if (environment.useMocks) {
      return new Machine().deserializeArray(MACHINES_MOCK);
    }

    let machines = this.machineList;
    if (forceRefresh || pagination || !this.machineList) {
      machines = await this.refreshMachines(pagination);
    } else if (this.isTimeToRefreshMachines()) {
      this.refreshMachines(pagination);
    }

    return machines;
  }

  async getMachine(id: string): Promise<Machine | undefined> {
    let machine: Machine = this.machineList?.find((m) => m.id === id);

    if (!environment.useMocks && !machine) {
      const {
        data: { slotMachine },
      } = await this.http.get<{ data: { slotMachine: MachineDTO } }>(`${this.apiUrl}/${id}`).toPromise();
      machine = new Machine().deserialize(slotMachine);
    }

    return machine;
  }

  async getMachinesByEstablishment(establishmentId: string): Promise<Machine[]> {
    let machinesDTO: MachineDTO[];
    if (environment.useMocks) {
      machinesDTO = MACHINES_MOCK.filter((m) => m.establishmentId === establishmentId);
    } else {
      let params = new HttpParams();
      params = params.append('establishmentId', establishmentId);
      ({
        data: { results: machinesDTO },
      } = await this.http.get<{ data: { results: MachineDTO[] } }>(`${this.apiUrl}/me`, { params }).toPromise());
    }

    return new Machine().deserializeArray(machinesDTO);
  }

  async saveMachine(machine: SaveMachineProps, id?: string): Promise<Machine> {
    let newMachine: Machine;
    if (id) {
      newMachine = await this.editMachine(id, machine);
    } else {
      newMachine = await this.createMachine(machine);
    }
    return newMachine;
  }

  async editMachine(id: string, machine: SaveMachineProps): Promise<Machine> {
    let updatedMachine: MachineDTO;

    if (environment.useMocks) {
      [updatedMachine] = MACHINES_MOCK;
    } else {
      ({ data: updatedMachine } = await this.http
        .put<{ data: MachineDTO }>(`${this.apiUrl}/${id}`, this.machineToMachineDTO(machine))
        .toPromise());
    }
    return new Machine().deserialize(updatedMachine);
  }

  async createMachine(machine: SaveMachineProps): Promise<Machine> {
    let newMachine: MachineDTO;

    if (environment.useMocks) {
      [newMachine] = MACHINES_MOCK;
    } else {
      ({ data: newMachine } = await this.http
        .post<{ data: MachineDTO }>(this.apiUrl, this.machineToMachineDTO(machine))
        .toPromise());
    }
    return new Machine().deserialize(newMachine);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteMachines(machines: Machine[]): Promise<void[]> {
    return Promise.all(machines.map((m) => this.deleteMachine(m)));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async deleteMachine(machine: Machine): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  private machineToMachineDTO(machine: SaveMachineProps): Partial<MachineDTO> {
    return {
      code: machine.code,
      deviceId: machine.deviceId,
      establishmentId: machine.establishmentId,
      modelName: machine.modelName,
      slotRawId: machine.code,
      noCollectThreshold: machine.valueNotCollect,
      collectThreshold: machine.valueCollect,
      creditValue: machine.totalValue,
      hopperCapacity: machine.totalValue,
      hopperAtRiskThreshold: machine.minHopper,
      hopperFailingThreshold: machine.maxHopper,
      winningFraudRiskThreshold: machine.mediumPercentage,
      winningFraudFailThreshold: machine.minimPercentage,
    };
  }
}
