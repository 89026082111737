import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';
import { Machine, TelemetryDetail, TelemetryMachine, TypeStateTelemetryDetail } from 'app/shared/models';
import { TELEMETRY_MOCK } from 'app/shared/mocks/telemetry-mock';
import { TelemetryDTO } from 'app/shared/interfaces';
import { MachineService } from './machine.service';
import { MachinePlateByEstablishmentService } from './machinePlateByEstablishment.service';
import { CollectionService } from './collection.service';

@Injectable({
  providedIn: 'root',
})
export class TelemetryMachineService {
  private readonly TIME_TO_LOAD_TELEMETRY = 1 * 60 * 1000; // milliseconds
  private readonly apiUrl = `${environment.apiUrl}/client/slot-machine-metrics`;

  private readonly telemetryMachineListSubject = new BehaviorSubject<Array<TelemetryMachine>>([]);
  readonly telemetryMachine$ = this.telemetryMachineListSubject.asObservable();

  private machines: Array<Machine> = [];
  private callTelemetry = false;

  constructor(
    private machineService: MachineService,
    private collectionService: CollectionService,
    private machinePlateByEstablishmentService: MachinePlateByEstablishmentService,
    private http: HttpClient,
  ) {
    setInterval(() => {
      if (this.callTelemetry) this.getTelemetryMachines();
    }, this.TIME_TO_LOAD_TELEMETRY);
  }

  get telemetryMachineList(): Array<TelemetryMachine> {
    return this.telemetryMachineListSubject.getValue();
  }

  setTelemetryMachineListSubject(telemetryMachineList: Array<TelemetryMachine>): void {
    this.telemetryMachineListSubject.next(telemetryMachineList);
  }

  stopCallTelemetry(): void {
    this.callTelemetry = false;
  }

  startCallTelemetry(): void {
    if (this.callTelemetry) {
      return;
    }
    this.callTelemetry = true;
    this.machineService.getMachines({ pagination: { limit: 20 } });
    this.machineService.machines$.subscribe((machines) => {
      if (!machines || this.machines.length > 0) return;
      this.setMachines(machines);
    });
  }

  async setMachines(machines: Array<Machine>): Promise<void> {
    this.machines = await this.machinePlateByEstablishmentService.getMachines(
      machines.filter((machine) => !!machine.deviceId),
    );
    this.getTelemetryMachines();
  }

  async getTelemetryMachine(machineId: string): Promise<TelemetryDetail | undefined> {
    let telemetryMachine: TelemetryDTO;

    if (environment.useMocks) {
      [telemetryMachine] = TELEMETRY_MOCK;
    } else {
      ({
        data: { telemetry: telemetryMachine },
      } = await this.http.get<{ data: { telemetry: TelemetryDTO } }>(`${this.apiUrl}/${machineId}`).toPromise());
    }

    return new TelemetryDetail().deserialize(telemetryMachine);
  }

  async getTelemetryMachines(): Promise<void> {
    if (!this.machines) {
      return;
    }

    const machinesWithPlates = this.machines.filter((machine) => machine.plate);
    const getTelemetryMachines = machinesWithPlates.map((machine: Machine) => this.getTelemetryMachine(machine.id));
    const getLastCollections = machinesWithPlates.map((machine: Machine) =>
      this.collectionService.getLastCollection(machine.id),
    );
    let telemetryMachine = [];

    try {
      const telemetryDetail = await Promise.all(getTelemetryMachines);
      const lastCollections = await Promise.all(getLastCollections);

      const machinesMap = new Map<string, Machine>();
      this.machines.forEach((machine) => {
        machinesMap.set(machine.deviceId, machine);
      });

      telemetryMachine = telemetryDetail
        .map((tDetail, index) => {
          const machine = machinesMap.get(tDetail.deviceId);

          if (machine === undefined) {
            return null;
          }
          const value = (tDetail.hopperMoney * 100) / machine.totalValue;
          if (tDetail.state === TypeStateTelemetryDetail.Connected && value === 0) {
            tDetail.setStateToOutOfService();
          }
          return new TelemetryMachine({
            machine,
            telemetryDetail: tDetail,
            lastCollection: lastCollections[index],
          });
        })
        .filter((collection) => !!collection);
      this.setTelemetryMachineListSubject(telemetryMachine);
      this.machineService.getMachines();
    } catch (err) {
      // err
    }
  }
}
