import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Plate } from 'app/shared/models';

// import { OPERATING_COMPANIES_MOCK } from 'app/shared/mocks/operatingCompany-mock';
import { HttpClient } from '@angular/common/http';
import { ApiPaginatedData, NkmApiResponse, PaginatedData, PlateDTO } from 'app/shared/interfaces';

export interface PlatesRequestProps {
  offset: number;
  limit: number;
  // ---
  organizationId?: string;
  deviceId?: string;
}

export interface CreatePlateProps {
  deviceId: string;
  organizationId?: string;
}

export interface UpdatePlateProps {
  plateId: string;
  deviceId?: string;
  organizationId?: string | null;
}

export type PlatesPaginated = PaginatedData<{ plates: Plate[] }>;

@Injectable({
  providedIn: 'root',
})
export class PlateAdminService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // GET DEVICES LIST - PLATES LIST
  async getPlates(props: PlatesRequestProps): Promise<PlatesPaginated> {
    const url = `${this.apiUrl}/admin/sbc-devices`;
    const { offset, limit, ...filters } = props;
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      ...filters,
    };

    const response = await this.http.get<NkmApiResponse<ApiPaginatedData<PlateDTO[]>>>(url, { params }).toPromise();

    const {
      data: { results, ...pagination },
    } = response;

    return {
      info: pagination,
      plates: new Plate().deserializeArray(results),
    };
  }

  // GET DEVICE - PLATE
  async getPlate(plateId: string): Promise<Plate> {
    const url = `${this.apiUrl}/admin/sbc-devices/${plateId}`;

    const response = await this.http.get<NkmApiResponse<{ sbcDevice: PlateDTO }>>(url).toPromise();
    const {
      data: { sbcDevice },
    } = response;

    return new Plate().deserialize(sbcDevice);
  }

  // CREATE DEVICE - PLATE
  async createPlate(props: CreatePlateProps): Promise<Plate> {
    const url = `${this.apiUrl}/admin/sbc-devices`;
    const response = await this.http.post<NkmApiResponse<{ sbcDevice: PlateDTO }>>(url, props).toPromise();
    const {
      data: { sbcDevice },
    } = response;
    return new Plate().deserialize(sbcDevice);
  }

  // UPDATE DEVICE - PLATE
  async updatePlate(props: UpdatePlateProps): Promise<Plate> {
    const { plateId, ...bodyParams } = props;
    const url = `${this.apiUrl}/admin/sbc-devices/${plateId}`;
    const body = {
      ...bodyParams,
    };
    const response = await this.http.put<NkmApiResponse<{ sbcDevice: PlateDTO }>>(url, body).toPromise();
    const {
      data: { sbcDevice },
    } = response;
    return new Plate().deserialize(sbcDevice);
  }

  // DELETE PLATE - PLATE
  async deletePlate(plateId: string): Promise<void> {
    const url = `${this.apiUrl}/admin/sbc-devices/${plateId}`;

    await this.http.delete(url).toPromise();
  }
}
