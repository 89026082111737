import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WeeklyTaskDTO } from 'app/shared/interfaces';
import { WEEKLY_TASKS_MOCK } from 'app/shared/mocks/weeklyTasks-mock';
import { Establishment, TaskNameEnum, WeeklyTask } from 'app/shared/models';
import { environment } from 'environments/environment';

interface NewWeeklyTasksProps {
  weekDay: number;
  userId: string;
  establishments: Establishment[];
  taskName: TaskNameEnum;
  organizationId: string;
}

interface NewWeeklyTaskProps {
  weekDay: number;
  userId: string;
  establishmentId: string;
  taskName: TaskNameEnum;
  organizationId: string;
}

@Injectable({
  providedIn: 'root',
})
export class WeeklyTaskService {
  private readonly apiUrl = `${environment.apiUrl}/client/weekly-tasks`;

  constructor(private http: HttpClient) {}

  async getWeeklyTasks(): Promise<WeeklyTask[]> {
    let weeklyTaskDTO: WeeklyTaskDTO[];
    if (environment.useMocks) {
      weeklyTaskDTO = WEEKLY_TASKS_MOCK;
    } else {
      const {
        data: { results },
      } = await this.http.get<{ data: { results: WeeklyTaskDTO[] } }>(`${this.apiUrl}/me`).toPromise();
      weeklyTaskDTO = results;
    }

    return new WeeklyTask().deserializeArray(weeklyTaskDTO);
  }

  private async createWeeklyTask(weeklyTask: NewWeeklyTaskProps): Promise<void> {
    if (environment.useMocks) {
      return;
    }

    try {
      await this.http.post<{ data: { results: WeeklyTaskDTO } }>(`${this.apiUrl}`, { ...weeklyTask }).toPromise();
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        const {
          code,
          errors: [errorMessage],
        } = err.error;
        if (code === 400 && errorMessage === 'WeeklyTask already exists') {
          return;
        }
      }
      throw err.error;
    }
  }

  async createWeeklyTaskToEstablishments(newWeeklyTaskProps: NewWeeklyTasksProps): Promise<void> {
    const { establishments, weekDay, userId, taskName, organizationId } = newWeeklyTaskProps;

    const promises = establishments.map(({ id }) =>
      this.createWeeklyTask({
        weekDay,
        userId,
        establishmentId: id,
        taskName,
        organizationId,
      }),
    );

    await Promise.all(promises);
  }
}
