export interface Collection {
  input: number;
  output: number;
  hopper: number;
  moneyWithdrawn: number;
  totalRecovery: number;
}

export interface Fault {
  input: number;
  output: number;
  amount: number;
}
