export enum TelemetryStateDTO {
  DISCONNECTED = 'disconnected',
  IDDLE = 'iddle',
  UNKNOWN = 'unknown',
  IN_GAME = 'in-game',
}
export interface TelemetryDTO {
  state: TelemetryStateDTO;
  deviceId: string;
  amount: number;
  averageAmount: number;
  percentageAwards: number;
  lastCollection: number;
  hopperMoney: number;
}
