import { NavItemI } from 'app/core/interfaces';

// import { environment } from 'environments/environment';

import { faStoreAlt, faUsers, faAtom, faBuilding, faMugHot } from '@fortawesome/free-solid-svg-icons';
import { faSlotMachine } from './icons/faSlotMachine';
import { actionsAllowed } from './utils';

const {
  dashboardListAction,
  telemetryListAction,
  brokenMachinesListAction,
  establishmentsAction,
  machinesAction,
  allUsersAction,
  collectorUsersAction,
} = actionsAllowed;

export const navigation: Array<NavItemI> = [
  // Admin nav items
  {
    name: 'Telemetrics',
    url: '/',
    icon: faStoreAlt,
    admin: false,
    actions: [dashboardListAction, telemetryListAction, brokenMachinesListAction],
  },
  {
    name: 'Establishments',
    url: '/establishments',
    icon: faMugHot,
    admin: false,
    actions: [establishmentsAction],
  },
  {
    name: 'Machines',
    url: '/machines',
    icon: faSlotMachine,
    admin: false,
    actions: [machinesAction],
  },
  {
    name: 'Users',
    url: '/users',
    icon: faUsers,
    admin: false,
    actions: [allUsersAction, collectorUsersAction],
  },
  {
    name: 'Plates',
    url: '/plates',
    icon: faAtom,
    admin: true,
  },
  {
    name: 'Empresas operadoras',
    url: '/operator-companies',
    icon: faBuilding,
    admin: true,
  },
  {
    name: 'Users',
    url: '/bo-users',
    icon: faUsers,
    admin: true,
  },
  /* {
    name: 'Test',
    url: '/test',
    hidden: !environment.development,
     children: [
      {
        name: 'List of usage reports',
        url: '/uses-of-works/list-of-usage-reports',
      },
      {
        name: 'Report use',
        url: '/uses-of-works/report-use',
      },
    ],
  }, */
];
