import { WeeklyTaskDTO } from '../interfaces';

export class WeeklyTask {
  id: string;
  weekDay: number;
  taskName: TaskNameEnum;
  slotMachine?: string;
  establishmentId?: string;
  userId?: string;

  deserialize(input: WeeklyTaskDTO): WeeklyTask {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.weekDay = input.weekDay;
    this.taskName = input.taskName as TaskNameEnum;
    this.slotMachine = input.slotMachine;
    this.establishmentId = input.establishmentId;
    this.userId = input.userId;

    return this;
  }

  deserializeArray(inputArray: Array<WeeklyTaskDTO>): Array<WeeklyTask> {
    const collectors: Array<WeeklyTask> = inputArray.map((input) => new WeeklyTask().deserialize(input));

    return collectors;
  }
}

export enum TaskNameEnum {
  HARVEST = 'harvest',
}
