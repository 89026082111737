<div [formGroup]="formGroup">
  <p class="mb-0">{{ 'TABLE_COLUMNS.PLATE' | translate }}</p>
  <ng-container *ngIf="plate; else noPlate">
    <div class="mt-1">
      <span class="font-primary-medium me-4">{{ plate.deviceId }}</span>
      <!-- <a
        class="cursor-pointer color-primary-blue me-4"
        target="_blank"
        [href]="'https://www.google.es/maps/place/' + plate.coordinates"
      >
        {{ plate.coordinates }}
        <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
      </a> -->
      <a
        class="cursor-pointer color-primary-blue font-size-md text-decoration-none"
        (click)="openConfirmationUnlinkedPlateModal()"
      >
        <fa-icon [icon]="faTrash"></fa-icon>
        {{ 'BUTTON.UNLINK_PLATE' | translate }}
      </a>
    </div>
  </ng-container>
  <ng-template #noPlate>
    <div class="d-flex align-items-center">
      <app-select
        class="col-md-4 me-3"
        formControlName="plate"
        [placeholder]="'TABLE_COLUMNS.PLATE' | translate"
        [options]="platesOptions"
      ></app-select>
      <button
        class="btn-awinsoft primary"
        (click)="linkPlate.emit(plateForm.value)"
        [disabled]="!plateForm.value"
      >
        <fa-icon [icon]="faPlus" class="me-1"></fa-icon>
        {{ 'BUTTON.LINK_PLATE' | translate }}
      </button>
    </div>
  </ng-template>
</div>

<ng-template #confirmUnlinkPlate let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ 'BUTTON.UNLINK_PLATE' | translate }}
    </h5>
    <button
      type="button"
      class="close"
      machines-dismiss="modal"
      aria-label="Close"
      (click)="modal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ 'PLATE.UNLINK_PLATE_QUESTION' | translate }}

    <div class="d-flex justify-content-end mt-3">
      <button class="btn-awinsoft secondary outline col-md-3" (click)="modal.close()">
        {{ 'BUTTON.CANCEL' | translate }}
      </button>
      <button class="btn-awinsoft primary col-md-3" (click)="modal.close(); unlinkPlate.emit()">
        {{ 'BUTTON.UNLINK' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #successfullyLinkedPlate let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ 'PLATE.LINKED_PLATE_SUCCESSFULLY' | translate }}
    </h5>
    <button
      type="button"
      class="close"
      machines-dismiss="modal"
      aria-label="Close"
      (click)="modal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <fa-icon [icon]="faCheckCircle" class="color-success" size="lg"></fa-icon>
    {{ 'PLATE.LINKED_PLATE_SUCCESSFULLY_DESC' | translate }}

    <div class="d-flex justify-content-end mt-3">
      <button class="btn-awinsoft primary col-md-3" (click)="modal.close()">
        {{ 'BUTTON.CLOSE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
