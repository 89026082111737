import { TypeOperatorCompanyUserRole } from '../enums';
import { Action, ActionNames } from '../interfaces/action';

const dashboardListAction: Action = { name: ActionNames.DASHBOARD, options: ['list'] };
const telemetryListAction: Action = { name: ActionNames.TELEMETRY, options: ['list'] };
const brokenMachinesListAction: Action = { name: ActionNames.BROKEN_MACHINES, options: ['list'] };
const establishmentsAction: Action = {
  name: ActionNames.ESTABLISHMENTS,
  options: ['list', 'read', 'create', 'delete', 'edit'],
};
const machinesAction: Action = {
  name: ActionNames.MACHINES,
  options: ['list', 'read', 'create', 'delete', 'edit'],
};
const allUsersAction: Action = { name: ActionNames.ALL_USERS, options: ['list', 'read', 'create', 'delete', 'edit'] };
const collectorUsersAction: Action = {
  name: ActionNames.COLLECTOR_USERS,
  options: ['list', 'read', 'create', 'delete', 'edit'],
};

export const actionsAllowed = {
  dashboardListAction,
  telemetryListAction,
  brokenMachinesListAction,
  establishmentsAction,
  machinesAction,
  allUsersAction,
  collectorUsersAction,
};

export class UserActionsUtils {
  static getActionsUserByRol(rol: TypeOperatorCompanyUserRole): Array<Action> {
    const actions = [];
    switch (rol) {
      case TypeOperatorCompanyUserRole.OWNER:
        actions.push(dashboardListAction);
        actions.push(telemetryListAction);
        actions.push(brokenMachinesListAction);
        actions.push(machinesAction);
        actions.push(establishmentsAction);
        actions.push(allUsersAction);
        break;
      case TypeOperatorCompanyUserRole.ADMINISTRATIVE:
        actions.push(dashboardListAction);
        actions.push(telemetryListAction);
        actions.push(brokenMachinesListAction);
        actions.push(collectorUsersAction);
        actions.push(machinesAction);
        actions.push(establishmentsAction);
        break;
      case TypeOperatorCompanyUserRole.COLLECTOR:
        actions.push(telemetryListAction);
        actions.push(brokenMachinesListAction);
        break;
      case TypeOperatorCompanyUserRole.SALESMAN:
        actions.push(dashboardListAction);
        break;
      default:
        break;
    }

    return actions;
  }

  static hasAction(action: Action, actions: Action[]): boolean {
    let hasAction = false;
    actions?.forEach((act) => {
      if (UserActionsUtils.sameAction(act, action)) {
        hasAction = true;
      }
    });
    return hasAction;
  }

  private static sameAction(action1: Action, action2: Action): boolean {
    if (action1.name !== action2.name) {
      return false;
    }
    return action1.options.every((opt) => action2.options.includes(opt));
  }
}
