export type ActionOptions = 'read' | 'delete' | 'create' | 'edit' | 'list';

export enum ActionNames {
  DASHBOARD,
  TELEMETRY,
  BROKEN_MACHINES,
  ALL_USERS,
  COLLECTOR_USERS,
  ESTABLISHMENTS,
  MACHINES,
}

export interface Action {
  name: ActionNames;
  options: ActionOptions[];
}
