import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserActionService } from 'app/modules/dashboard/services';
import { Action } from '../interfaces';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective implements OnChanges {
  @Input() actions: Action[];

  constructor(private el: ElementRef, private userActionService: UserActionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions && changes.actions.currentValue) {
      this.checkPermission();
    }
  }

  checkPermission(): void {
    if (!this.userActionService.hasOnePermission(this.actions)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
