import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSidebarToggler]',
})
export class SidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: Event): void {
    $event.preventDefault();

    document.querySelector('.layout').classList.toggle('show-sidebar');

    if (document.querySelector('.layout').classList.contains('show-sidebar')) {
      document.querySelector('.sidebar-toggler-open').classList.remove('show');
      document.querySelectorAll('.sidebar-toggler-close').forEach((node) => node.classList.add('show'));
      return;
    }

    document.querySelector('.sidebar-toggler-open').classList.add('show');
    document.querySelectorAll('.sidebar-toggler-close').forEach((node) => node.classList.remove('show'));
  }
}
