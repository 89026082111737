/* eslint-disable max-classes-per-file */
import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { NavItemI } from 'app/core/interfaces';
import { navigation } from 'app/shared/navigation';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';

@Component({
  selector: 'app-sidebar-menu',
  styleUrls: ['./app-sidebar-menu.component.scss'],
  template: `<ul class="navbar-nav">
    <ng-template ngFor let-navitem [ngForOf]="navigation">
      <ng-template [ngIf]="!navitem.hidden">
        <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
        <ng-template [ngIf]="isTitle(navitem)">
          <app-sidebar-nav-title [title]="navitem"></app-sidebar-nav-title>
        </ng-template>
        <ng-template [ngIf]="!isDivider(navitem) && !isTitle(navitem)">
          <app-sidebar-nav-item [item]="navitem" appHasPermission [actions]="navitem.actions"></app-sidebar-nav-item>
        </ng-template>
      </ng-template>
    </ng-template>
  </ul> `,
})
export class AppSidebarMenuComponent implements OnInit {
  public navigation = navigation;
  isCurrentUserAdmin = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isCurrentUserAdmin = this.authService.isAdminUser();
    this.navigation = this.navigation.filter((navItem) => navItem.admin === this.isCurrentUserAdmin);
  }

  public isDivider(item: NavItemI): boolean {
    return !!item.divider;
  }

  public isTitle(item: NavItemI): boolean {
    return !!item.title;
  }
}

@Component({
  selector: 'app-sidebar-nav-item',
  template: `<li *ngIf="!isDropdown(); else dropdown"><app-sidebar-nav-link [link]="item"></app-sidebar-nav-link></li>
    <ng-template #dropdown>
      <li [ngClass]="getClass()" [class.mb-3]="!isActive()" [class.show]="isActive()" appNavDropdown>
        <app-sidebar-nav-dropdown [link]="item"></app-sidebar-nav-dropdown>
      </li>
    </ng-template> `,
})
export class AppSidebarNavItemComponent {
  @Input() item: NavItemI;

  public hasClass(): boolean {
    return !!this.item.class;
  }

  public isDropdown(): boolean {
    return !!this.item.children;
  }

  public thisUrl(): string {
    return this.item.url;
  }

  public isActive(): boolean {
    return this.router.isActive(this.thisUrl(), true);
  }

  public getClass(): string {
    return this.hasClass() ? `nav-item dropdown ${this.item.class}` : 'nav-item dropdown';
  }

  constructor(private router: Router) {}
}

@Component({
  selector: 'app-sidebar-nav-link',
  template: `<a *ngIf="isChild" class="dropdown-item" [class.active]="isActive()" [routerLink]="[link.url]">
      <fa-icon *ngIf="isIcon()" class="nav-icon" [icon]="link.icon"></fa-icon> {{ link.name | translate }} </a
    ><a
      *ngIf="!isChild && !isExternalLink()"
      [ngClass]="getClass()"
      [class.active]="isActive()"
      [routerLink]="[link.url]"
    >
      <fa-icon *ngIf="isIcon()" class="nav-icon" [icon]="link.icon"></fa-icon> {{ link.name | translate }}
    </a>
    <a *ngIf="!isChild && isExternalLink()" [ngClass]="getClass()" href="{{ link.url }}">
      <fa-icon *ngIf="isIcon()" class="nav-icon" [icon]="link.icon"></fa-icon> {{ link.name | translate }}
    </a> `,
})
export class AppSidebarNavLinkComponent {
  @Input() link: NavItemI;
  @Input() isChild: boolean;

  public hasVariant(): boolean {
    return !!this.link.variant;
  }

  public isExternalLink(): boolean {
    return this.link.url.substring(0, 4) === 'http';
  }

  public isIcon(): boolean {
    return !!this.link.icon;
  }

  public thisUrl(): string {
    return this.link.url ? this.link.url : '';
  }

  public isActive(): boolean {
    return this.router.isActive(this.thisUrl(), true);
  }

  public getClass(): string {
    return this.hasVariant() ? `nav-link mb-4 nav-link-${this.link.variant}` : 'nav-link mb-4';
  }

  constructor(private router: Router) {}
}

@Component({
  selector: 'app-sidebar-nav-dropdown',
  template: `<a class="nav-link" [class.active]="isActive()" appNavDropdownToggle>
      <fa-icon *ngIf="isIcon()" class="nav-icon" [icon]="link.icon"></fa-icon> {{ link.name | translate }}
      <fa-icon class="d-block float-right" [icon]="faChevronDown"></fa-icon>
    </a>
    <div class="dropdown-menu" [class.show]="isActive()" routerLinkActive="active">
      <ng-template ngFor let-child [ngForOf]="link.children">
        <app-sidebar-nav-link [isChild]="true" [link]="child"></app-sidebar-nav-link>
      </ng-template>
    </div> `,
})
export class AppSidebarNavDropdownComponent {
  @Input() link: NavItemI;
  faChevronDown = faChevronDown;

  constructor(private router: Router) {}

  public isIcon(): boolean {
    return !!this.link.icon;
  }

  public thisUrl(): string {
    return this.link.url;
  }

  public isActive(): boolean {
    return this.router.isActive(this.thisUrl(), false);
  }
}

@Component({
  selector: 'app-sidebar-nav-title',
  template: '',
})
export class AppSidebarNavTitleComponent implements OnInit {
  @Input() title: NavItemI;

  constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  ngOnInit(): void {
    const { nativeElement } = this.el;
    const li = <HTMLLIElement>this.renderer.createElement('li');
    const name = <HTMLSpanElement>this.renderer.createText(this.title.name);
    this.renderer.addClass(li, 'nav-title');

    if (this.title.class) {
      const classes = this.title.class;
      this.renderer.addClass(li, classes);
    }

    this.renderer.appendChild(li, name);
    this.renderer.appendChild(nativeElement, li);
  }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarMenuComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavTitleComponent,
];
