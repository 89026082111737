import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

export const faSlotMachine: IconDefinition = {
  prefix: 'fab' as IconPrefix,
  iconName: 'slotMachine' as IconName,
  icon: [
    24,
    20,
    [],
    '',
    'M8.48438 16.1641V4.91406H15.5156V16.1641H8.48438ZM13.9034 10.3331L14.1094 10.1271V7.72656H9.89062V9.83594H11.2969V9.13281H12.7031V9.55652C11.8063 10.5598 11.2969 11.8821 11.2969 13.2307V14.0547H12.7031V13.2307C12.7031 12.152 13.1406 11.0959 13.9034 10.3331Z M0 0.695312H24V3.50781H0V0.695312Z M0 17.5703H24V20.3828H0V17.5703Z M0 4.91406H7.07813V16.1641H0V4.91406ZM5.46588 10.3331L5.67188 10.1271V7.72656H1.45312V9.83594H2.85938V9.13281H4.26562V9.55652C3.36877 10.5598 2.85938 11.8821 2.85938 13.2307V14.0547H4.26562V13.2307C4.26562 12.152 4.70306 11.0959 5.46588 10.3331Z M24 16.1641H16.9219V4.91406H24V16.1641ZM22.5469 7.72656H18.3281V9.83594H19.7344V9.13281H21.1406V9.55652C20.2438 10.5598 19.7344 11.8821 19.7344 13.2307V14.0547H21.1406V13.2307C21.1406 12.152 21.5781 11.0959 22.3409 10.3331L22.5469 10.1271V7.72656Z',
  ],
};
