import { WeeklyTaskDTO } from '../interfaces';
import { ESTABLISHMENTS_MOCK } from './establishment-mock';
import { USER_MOCK_COLLECTOR } from './user-mock';

const userId = USER_MOCK_COLLECTOR.id;
const establishmentId = ESTABLISHMENTS_MOCK[0].id;

export const WEEKLY_TASKS_MOCK: Array<WeeklyTaskDTO> = [
  {
    id: 'id',
    organizationId: 'organizationId',
    weekDay: 1,
    taskName: 'harvest',
    establishmentId,
    userId,
  },
  {
    id: 'id',
    organizationId: 'organizationId',
    weekDay: 2,
    taskName: 'harvest',
    establishmentId,
    userId,
  },
];
