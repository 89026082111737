import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ValidationUtils {
  public static passwordAndRepasswordAreEqual(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.passwordAndRepasswordEqual) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ passwordAndRepasswordEqual: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
