import { OperatorCompanyDTO } from '../interfaces';
import { TypeSubscriptionOperatorCompany } from '../models';
// import { OWNERS_MOCK } from './owner-mock';

// const [owner1, owner2, owner3] = OWNERS_MOCK;
export const OPERATING_COMPANIES_MOCK: OperatorCompanyDTO[] = [
  {
    id: 'opcp1',
    createdAt: new Date(),
    updatedAt: new Date(),
    code: 'EST1',
    name: 'Company 1',
    nif: '12345678-G',
    // town: 'Barcelona',
    subscriptionCode: TypeSubscriptionOperatorCompany.Premium,
    // email: 'company@awin.es',
    // state: TypeStateOperatorCompany.Active,
    // owner: owner1,
  },
  {
    id: 'opcp2',
    createdAt: new Date(),
    updatedAt: new Date(),
    code: 'EST2',
    name: 'Company 2',
    nif: '12345678-G',
    // town: 'Barcelona',
    subscriptionCode: TypeSubscriptionOperatorCompany.Premium,
    // email: 'company@awin.es',
    // state: TypeStateOperatorCompany.Inactive,
    // owner: owner2,
  },
  {
    id: 'opcp3',
    createdAt: new Date(),
    updatedAt: new Date(),
    code: 'EST3',
    name: 'Company 3',
    nif: '12345678-G',
    // town: 'Barcelona',
    subscriptionCode: TypeSubscriptionOperatorCompany.Normal,
    // email: 'company@awin.es',
    // state: TypeStateOperatorCompany.Active,
    // owner: owner3,
  },
];
