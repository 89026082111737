import { KeyHopperLimit } from '../models';

export class BulletColorHopperLimit {
  public static getBulletColorByKey(key: KeyHopperLimit): string {
    if (key === 'low') {
      return 'bullet-red';
    }
    if (key === 'medium') {
      return 'bullet-orange';
    }
    return 'bullet-green';
  }

  public static getBulletTextByKey(key: KeyHopperLimit): string {
    if (key === 'low') {
      return '(<25%)';
    }
    if (key === 'medium') {
      return '(<50%)';
    }
    return '(OK)';
  }
}
