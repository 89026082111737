import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { OperatorCompany } from 'app/shared/models';

import { BehaviorSubject } from 'rxjs';
import { PlateAdminService, PlatesPaginated, PlatesRequestProps } from './plate.service';
import { OperatorCompanyAdminService } from './operatorCompany.service';

@Injectable({
  providedIn: 'root',
})
export class PlateWithOperatorCompanyService {
  private readonly allOperatorCompaniesMapSubject = new BehaviorSubject<Map<string, OperatorCompany>>(null);
  readonly allOperatorCompaniesMap$ = this.allOperatorCompaniesMapSubject.asObservable();
  private readonly apiUrl = environment.apiUrl;

  constructor(private plateService: PlateAdminService, private operatorCompanyService: OperatorCompanyAdminService) {}

  getCurrentAllOperatorCompaniesMap(): Map<string, OperatorCompany> {
    return this.allOperatorCompaniesMapSubject.getValue();
  }

  private set allOperatorCompaniesMap(operatorCompaniesMap: Map<string, OperatorCompany>) {
    this.allOperatorCompaniesMapSubject.next(operatorCompaniesMap);
  }

  // GET PLATES LIST + OPERATOR_COMPANY
  async getPlatesWithOperatorCompany(props: PlatesRequestProps): Promise<PlatesPaginated> {
    const { info, plates } = await this.plateService.getPlates(props);

    const allCurrentOperatorCompaniesMap = this.getCurrentAllOperatorCompaniesMap();

    if (!allCurrentOperatorCompaniesMap) {
      await this.getAllOperatorCompanies();
    }

    const allOperatorCompaniesMap = this.getCurrentAllOperatorCompaniesMap();

    plates.forEach(async (plate) => {
      let hasNewOC = false;
      if (plate.operatorCompanyId) {
        const operatorCompany = allOperatorCompaniesMap.get(plate.operatorCompanyId);
        if (operatorCompany) {
          plate.setOperatorCompany(operatorCompany);
        } else {
          const operatorCompanyFetched = await this.operatorCompanyService.getOperatorCompany(plate.operatorCompanyId);
          if (operatorCompanyFetched) {
            plate.setOperatorCompany(operatorCompanyFetched);
            hasNewOC = true;
          }
        }
      }
      if (hasNewOC) {
        await this.getAllOperatorCompanies();
      }
    });

    return {
      info,
      plates,
    };
  }

  async getAllOperatorCompanies(): Promise<void> {
    const { allOperatorCompaniesMap } = await this.operatorCompanyService.getAllOperatorCompaniesMap();
    this.allOperatorCompaniesMap = allOperatorCompaniesMap;
  }
}
