import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { OperatorCompany, Plate } from 'app/shared/models';

import { PLATES_MOCK } from 'app/shared/mocks/plate-mock';
import { OPERATING_COMPANIES_MOCK } from 'app/shared/mocks/operatingCompany-mock';
import { HttpClient } from '@angular/common/http';
import { PaginationI, PlateDTO } from 'app/shared/interfaces';
import { BehaviorSubject } from 'rxjs';

// TODO Move when defined correctly
export interface OperatingCompaniesI {
  text?: string;
}

export interface OperatingCompnaiesResponse {
  operatingCompanies: Array<OperatorCompany>;
  info: PaginationI;
}

@Injectable({
  providedIn: 'root',
})
export class PlateService {
  private readonly apiUrl = `${environment.apiUrl}/client/sbc-devices`;

  private readonly TIME_TO_LOAD_PLATES = 5 * 60 * 1000; // milliseconds
  private lastRefresPlates: Date;

  private readonly plateListSubject = new BehaviorSubject<Array<Plate>>(null);
  readonly plates$ = this.plateListSubject.asObservable();

  constructor(private http: HttpClient) {}

  get plateList(): Array<Plate> {
    return this.plateListSubject.getValue();
  }

  setPlateListSubject(plateList: Array<Plate>): void {
    this.plateListSubject.next(plateList);
  }

  private isTimeToRefreshPlates(): boolean {
    if (this.lastRefresPlates === undefined) return true;

    const now = new Date();
    const differenceBetweenTime = now.getTime() - this.lastRefresPlates.getTime();

    return differenceBetweenTime >= this.TIME_TO_LOAD_PLATES;
  }

  private async loadPlates(): Promise<Array<Plate>> {
    const {
      data: { results },
    } = await this.http.get<{ data: { results: PlateDTO[] } }>(`${this.apiUrl}/me`).toPromise();
    return new Plate().deserializeArray(results);
  }

  private async refreshMachines(): Promise<void> {
    const machines = await this.loadPlates();

    this.setPlateListSubject(machines);
    this.lastRefresPlates = new Date();
  }

  async getPlates(forceRefresh?: boolean): Promise<Array<Plate>> {
    if (environment.useMocks) {
      return new Plate().deserializeArray(PLATES_MOCK);
    }

    if (forceRefresh || !this.plateList) {
      await this.refreshMachines();
    } else if (this.isTimeToRefreshPlates()) {
      this.refreshMachines();
    }

    return this.plateList;
  }

  async getOperantingCompanies(
    offset: number,
    limit: number,
    filters?: OperatingCompaniesI,
  ): Promise<OperatingCompnaiesResponse> {
    const nameToSearch = filters.text ? filters.text : '';
    const operatingCompanies = environment.useMocks
      ? OPERATING_COMPANIES_MOCK.filter((company) => company.name.includes(nameToSearch) === true)
      : [];
    return {
      operatingCompanies: new OperatorCompany().deserializeArray(operatingCompanies),
      info: {
        offset,
        limit,
      },
    };
  }

  async getPlate(deviceId: string): Promise<Plate> {
    let plate: PlateDTO;
    if (environment.useMocks) {
      const plates = PLATES_MOCK.filter((pl) => pl.deviceId === deviceId);
      [plate] = plates;
    } else {
      ({
        data: { sbcDevice: plate },
      } = await this.http.get<{ data: { sbcDevice: PlateDTO } }>(`${this.apiUrl}/${deviceId}`).toPromise());
    }
    return new Plate().deserialize(plate);
  }

  async updatePlate(plate: Plate): Promise<Plate> {
    return plate;
  }
}
