import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { OperatorCompany, TypeUserRole, User } from 'app/shared/models';
import { USER_MOCK } from 'app/shared/mocks/user-mock';
import { OperatorCompanyDTO, UserDTO } from 'app/shared/interfaces/dto';
import { Session } from 'app/shared/interfaces';
import { TypeOperatorCompanyUserRole } from 'app/shared/enums';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface AuthResponse {
  user: UserDTO;
  token: string;
}
interface OrganizationUserRoles {
  userId: string;
  orgRole: TypeOperatorCompanyUserRole;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly sessionSubject = new BehaviorSubject<Session>(null);
  readonly session$ = this.sessionSubject.asObservable();

  private readonly sessionKey = environment.sessionKey;
  private readonly apiUrl = `${environment.apiUrl}/public`;

  private localStorageService: Storage;
  private currentUser: User;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastrService,
    private translate: TranslateService,
  ) {
    this.localStorageService = localStorage;
    this.session = this.loadSessionData();
  }

  getCurrentSession(): Session {
    return this.sessionSubject.getValue();
  }

  private set session(session: Session) {
    this.sessionSubject.next(session);
    this.localStorageService.setItem(this.sessionKey, JSON.stringify(session));
    this.currentUser = session && session.user;
  }

  loadSessionData(): Session | undefined {
    const sessionStr = this.localStorageService.getItem(this.sessionKey);
    try {
      const session = JSON.parse(sessionStr) as Session;
      const { user, token } = session;
      return {
        token,
        user: new User().deserialize(user),
      };
    } catch (error) {
      // console.log(error);
      this.toast.error(this.translate.instant('AUTH_SERVICE.ERROR_LOAD_SESSION'));
      return undefined;
    }
  }

  getCurrentUser(): User {
    if (!this.currentUser) {
      const session: Session = this.getCurrentSession();
      this.currentUser = session && session.user ? new User().deserialize(session.user) : null;
    }

    return this.currentUser;
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return session && session.token ? session.token : null;
  }

  isAuthenticated(): boolean {
    return this.getCurrentToken() !== null;
  }

  isAdminUser(): boolean {
    const user: User = this.getCurrentUser();

    return user.role === TypeUserRole.ADMINISTRATOR;
  }

  removeSession(): void {
    this.session = null;
  }

  async login(email: string, password: string): Promise<Session> {
    let session: Session = null;

    if (environment.useMocks) {
      session = {
        user: new User().deserialize(USER_MOCK),
        token: 'asdasdasdasd',
      };
    } else {
      const url = `${this.apiUrl}/auth/login/local`;

      const { data } = await this.http.post<{ data: AuthResponse }>(url, { email, password }).toPromise();

      const { user, token } = data;

      session = {
        user: new User().deserialize(user),
        token,
      };

      this.session = session;
      if (user.role === 'client') {
        const operatorCompany = await this.getOrganizationByMe();
        const userInfo = await this.getOperatorCompanyUserRol(operatorCompany.id, user.id);

        session = {
          user: new User().deserialize({ ...user, operatorCompany, orgRole: userInfo.orgRole }),
          token,
        };
      }
    }

    this.session = session;
    return session;
  }

  async recoverPassword(email: string): Promise<void> {
    if (environment.useMocks) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 300);
      });
    }
    const url = `${this.apiUrl}/auth/psw/recover/`;

    return this.http.post<void>(url, { email }).toPromise();
  }

  logout(): void {
    this.removeSession();
  }

  private async getOrganizationByMe() {
    const {
      data: { organization },
    } = await this.http
      .get<{ data: { organization: OperatorCompanyDTO } }>(`${environment.apiUrl}/client/organizations/me`)
      .toPromise();

    return new OperatorCompany().deserialize(organization);
  }

  private async getOperatorCompanyUserRol(operatorCompanyId: string, userId: string): Promise<OrganizationUserRoles> {
    const url = `${environment.apiUrl}/client/organizations/${operatorCompanyId}/users`;
    const params = {
      userId,
    };
    const { data } = await this.http.get<{ data: { orgUsers: OrganizationUserRoles[] } }>(url, { params }).toPromise();

    const { orgUsers } = data;

    const [orgUser] = orgUsers;
    return orgUser;
  }
}
