import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PaginationControlsDirective, PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss'],
})
export class AppPaginationComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  formPagination: FormGroup;
  goToPage: FormControl;

  @Input() config: PaginationInstance;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    this.goToPage = new FormControl(0);
    this.formPagination = new FormGroup({
      goToPage: this.goToPage,
    });
  }

  change(event: number): void {
    this.config.currentPage = event;
    this.pageChange.emit(event);
  }

  goToPageFn(p: PaginationControlsDirective): void {
    const { value } = this.goToPage;
    const lastPage = this.config.totalItems / this.config.itemsPerPage;
    if (value && value <= lastPage) {
      p.setCurrent(value);
    }
  }

  goToNextPageFn(p: PaginationControlsDirective): void {
    const lastPage = this.config.totalItems / this.config.itemsPerPage;
    let { currentPage } = this.config;
    currentPage += 1;
    if (currentPage <= lastPage) {
      p.next();
    }
  }
}
