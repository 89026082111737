import { PlateDTO } from '../interfaces/dto/plateDTO';

export const PLATES_MOCK: PlateDTO[] = [
  {
    id: 'PLT1',
    deviceId: 'PLT1',
    organizationId: 'PLT1_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT2',
    deviceId: 'PLT2',
    organizationId: 'PLT2_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT3',
    deviceId: 'PLT3',
    organizationId: 'PLT3_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT4',
    deviceId: 'PLT4',
    organizationId: 'PLT4_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT5',
    deviceId: 'PLT5',
    organizationId: 'PLT5_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT6',
    deviceId: 'PLT6',
    organizationId: 'PLT6_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT7',
    deviceId: 'PLT7',
    organizationId: 'PLT7_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT8',
    deviceId: 'PLT8',
    organizationId: 'PLT8_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT9',
    deviceId: 'PLT9',
    organizationId: 'PLT9_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT10',
    deviceId: 'PLT10',
    organizationId: 'PLT10_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT11',
    deviceId: 'PLT11',
    organizationId: 'PLT11_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT12',
    deviceId: 'PLT12',
    organizationId: 'PLT12_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT13',
    deviceId: 'PLT13',
    organizationId: 'PLT13_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT14',
    deviceId: 'PLT14',
    organizationId: 'PLT14_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT15',
    deviceId: 'PLT15',
    organizationId: 'PLT15_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT16',
    deviceId: 'PLT16',
    organizationId: 'PLT16_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT17',
    deviceId: 'PLT17',
    organizationId: 'PLT17_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT18',
    deviceId: 'PLT18',
    organizationId: 'PLT18_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT19',
    deviceId: 'PLT19',
    organizationId: 'PLT19_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT20',
    deviceId: 'PLT20',
    organizationId: 'PLT20_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT21',
    deviceId: 'PLT21',
    organizationId: 'PLT21_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT22',
    deviceId: 'PLT22',
    organizationId: 'PLT22_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: 'PLT23',
    deviceId: 'PLT23',
    organizationId: 'PLT23_ORG',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
