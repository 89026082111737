import { TypePercentageAwards } from '../models';

export class PercentageAwardsUtils {
  public static getTypePercentageAwards(percentage: number): TypePercentageAwards {
    if (percentage > 90) {
      return TypePercentageAwards.high;
    }
    if (percentage > 70) {
      return TypePercentageAwards.medium;
    }
    return TypePercentageAwards.low;
  }

  public static getBulletColorByType(type: TypePercentageAwards): string {
    if (type === TypePercentageAwards.high) {
      return 'bullet-red';
    }
    if (type === TypePercentageAwards.medium) {
      return 'bullet-orange';
    }
    return 'bullet-green';
  }

  public static getBulletTextByType(type: TypePercentageAwards): string {
    if (type === TypePercentageAwards.high) {
      return '(>90%)';
    }
    if (type === TypePercentageAwards.medium) {
      return '(70% - 90%)';
    }
    return '(<70%)';
  }
}
