// import { PLATES_MOCK } from './plate-mock';
import { MachineDTO } from '../interfaces';

export const MACHINES_MOCK: MachineDTO[] = [
  {
    id: '828928',
    // establishment: 'BAR EL MESON',
    modelName: 'MANHATTAN ADVANCED',
    slotRawId: '828928',
    code: '828928',
    // plate: PLATES_MOCK[0],
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
  },
  {
    id: '828928A',
    // establishment: 'BAR EL MESON',
    modelName: 'NOVOLINE BAR III R',
    slotRawId: '82892811',
    code: '828928A',
    // plate: PLATES_MOCK[1],
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
  },
  {
    id: '828928B',
    // establishment: 'BAR CAP I CUA',
    modelName: 'NOVO LINE ROYAL CASH',
    slotRawId: '82892811',
    code: '828928B',
    // plate: PLATES_MOCK[2],
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
  },
  {
    id: '828928D',
    // establishment: 'BAR CAP I CUA',
    modelName: 'GIGAMES LUCKY BROTHERS',
    slotRawId: '82892800',
    code: '828928D',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[3],
  },
  {
    id: '828928E',
    // establishment: "BAR L'ESCALA",
    modelName: 'NOVO LINE POWER CASH',
    slotRawId: '82892812',
    code: '828928E',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[4],
  },
  {
    id: '828928F',
    // establishment: "BAR L'ESCALA",
    modelName: 'MANHATTAN REEVOLUTION',
    slotRawId: '82892812',
    code: '828928F',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[5],
  },
  {
    id: '828928G',
    // establishment: 'BAR LA PARADA',
    modelName: 'NOVO LINE BAR III R',
    slotRawId: '82892813',
    code: '828928G',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[6],
  },
  {
    id: '828928H',
    // establishment: 'BAR LA PARADA',
    modelName: 'ACTION STAR',
    slotRawId: '82892813',
    code: '828928H',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[7],
  },
  {
    id: '828928I',
    // establishment: 'BAR LA PARADA',
    modelName: 'LA PERLA DEL CARIBE DELUXE',
    slotRawId: '82892813',
    code: '828928I',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[8],
  },
  {
    id: '828928J',
    // establishment: 'BAR MARE NOSTRUM',
    modelName: 'NOVO LINE ROYAL CASH',
    slotRawId: '82892814',
    code: '828928J',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[9],
  },
  {
    id: '828928K',
    // establishment: 'BAR MARE NOSTRUM',
    modelName: 'NOVO LINE BAR III R',
    slotRawId: '82892814',
    code: '828928K',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[10],
  },
  {
    id: '828928L',
    // establishment: 'BAR MARE NOSTRUM',
    modelName: 'ACTION STAR',
    slotRawId: '82892814',
    code: '828928L',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[11],
  },
  {
    id: '828928M',
    // establishment: 'BAR LA GALERIA',
    modelName: 'NOVO LINE POWER CASH',
    slotRawId: '82892815',
    code: '828928M',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[12],
  },
  {
    id: '828928N',
    // establishment: 'BAR LA GALERIA',
    modelName: 'MANHATTAN ADVANCED',
    slotRawId: '82892815',
    code: '828928N',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[13],
  },
  {
    id: '828928O',
    // establishment: 'BAR LA PEDRA',
    modelName: 'GIGAMES LUCKY BROTHERS',
    slotRawId: '82892816',
    code: '828928O',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[14],
  },
  {
    id: '828928P',
    // establishment: 'BAR LA PEDRA',
    modelName: 'NOVO LINE BAR III R',
    slotRawId: '82892816',
    code: '828928P',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[15],
  },
  {
    id: '828928Q',
    // establishment: 'BAR 1880',
    modelName: 'MANHATTAN ADVANCED',
    slotRawId: '82892817',
    code: '828928Q',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[16],
  },
  {
    id: '828928R',
    // establishment: 'BAR 1880',
    modelName: 'NOVOLINE POWER CASH',
    slotRawId: '82892817',
    code: '828928R',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[17],
  },
  {
    id: '828928S',
    // establishment: 'BAR VIENA',
    modelName: 'NOVO LINE POWER CASH',
    slotRawId: '82892818',
    code: '828928S',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[18],
  },
  {
    id: '828928T',
    // establishment: 'BAR ROMA',
    modelName: 'MANHATTAN REEVOLUTION',
    slotRawId: '82892819',
    code: '828928T',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[19],
  },
  {
    id: '828928U',
    // establishment: 'BAR ROMA',
    modelName: 'NOVO LINE BAR III R',
    slotRawId: '82892819',
    code: '828928U',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[20],
  },
  {
    id: '828928V',
    // establishment: 'BAR LA TASCA',
    modelName: 'ACTION STAR',
    slotRawId: '82892820',
    code: '828928V',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[21],
  },
  {
    id: '828928X',
    // establishment: 'BAR LA TASCA',
    modelName: 'ACTION STAR',
    slotRawId: '82892820',
    code: '828928X',
    noCollectThreshold: 200,
    collectThreshold: 400,
    creditValue: 1000,
    // minHopper: 250,
    hopperCapacity: 500,
    hopperAtRiskThreshold: 50,
    hopperFailingThreshold: 25,
    winningFraudRiskThreshold: 50,
    winningFraudFailThreshold: 50,
    // plate: PLATES_MOCK[22],
  },
];
