import dayjs from 'dayjs';
import { SlotCounter } from '../interfaces';
import { MACHINES_MOCK } from './machine-mock';

const [machine1, machine2] = MACHINES_MOCK;

const now = dayjs();

export const SLOT_COUNTERS_MOCK: Array<SlotCounter> = [
  { slotMachineId: machine1.id, timestamp: now.toDate() },
  { slotMachineId: machine1.id, timestamp: now.subtract(1, 'day').toDate() },
  { slotMachineId: machine2.id, timestamp: now.toDate() },
];
